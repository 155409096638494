<template>
  <div class="vx-row">
    <vs-popup title="Impression assurance" :active.sync="popupActiveImprime">
      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-select label="Proposition à imprimer" autocomplete class="selectExample w-full select-large mt-4" v-model.lazy="propositionPourImpression">
            <vs-select-item class="w-full" :key="index" :value="item" :text="item" v-for="(item,index) in ['1','2','3']" />
          </vs-select>
        </div>
        <div class="vx-col w-full">
          <template v-if="propositionPourImpression === '1'">
            <vs-select label="Fournisseur monture" autocomplete class="selectExample w-full select-large mt-4" v-model.lazy="FourM1">
              <vs-select-item class="w-full" :key="index" :value="item.Nom" :text="item.Nom" v-for="(item,index) in fournisseurMontures" />
            </vs-select>
          </template>
          <template v-if="propositionPourImpression === '2'">
            <vs-select label="Fournisseur monture" autocomplete class="selectExample w-full select-large mt-4" v-model.lazy="FourM2">
              <vs-select-item class="w-full" :key="index" :value="item.Nom" :text="item.Nom" v-for="(item,index) in fournisseurMontures" />
            </vs-select>
          </template>
          <template v-if="propositionPourImpression === '3'">
            <vs-select label="Fournisseur monture" autocomplete class="selectExample w-full select-large mt-4" v-model.lazy="FourM3">
              <vs-select-item class="w-full" :key="index" :value="item.Nom" :text="item.Nom" v-for="(item,index) in fournisseurMontures" />
            </vs-select>
          </template>
        </div>
        <div class="vx-col w-full">
          <template v-if="propositionPourImpression === '1'">
            <vs-select label="Fournisseur verre" autocomplete class="selectExample w-full select-large mt-4" v-model.lazy="FourV1">
              <vs-select-item class="w-full" :key="index" :value="item.Nom" :text="item.Nom" v-for="(item,index) in fournisseurVerres" />
            </vs-select>
          </template>
          <template v-if="propositionPourImpression === '2'">
            <vs-select label="Fournisseur verre" autocomplete class="selectExample w-full select-large mt-4" v-model.lazy="FourV2">
              <vs-select-item class="w-full" :key="index" :value="item.Nom" :text="item.Nom" v-for="(item,index) in fournisseurVerres" />
            </vs-select>
          </template>
          <template v-if="propositionPourImpression === '3'">
            <vs-select label="Fournisseur verre" autocomplete class="selectExample w-full select-large mt-4" v-model.lazy="FourV3">
              <vs-select-item class="w-full" :key="index" :value="item.Nom" :text="item.Nom" v-for="(item,index) in fournisseurVerres" />
            </vs-select>
          </template>
        </div>
        <div class="vx-col w-full">
          <vs-input class="w-full mt-4" type="text" label="Garant"  name="garant" :value="garant" @change.native="garant=$event.target.value" />
        </div>
        <div class="vx-col md:w-1/2">
          <vs-select label="Photochromique" autocomplete class="selectExample w-full select-large mt-4" v-model.lazy="nomComPhotogray" @change="setPhotograyPrix(nomComPhotogray)">
            <vs-select-item class="w-full" :key="index" :value="item.libelle" :text="item.libelle" v-for="(item,index) in listePhotochromiques" />
          </vs-select>
        </div>
        <div class="vx-col md:w-1/2">
          <vs-input class="w-full mt-4" type="number" label="Prix Photochromique"  name="prixPhotogray" :value="prixPhotogray" @change.native="prixPhotogray=$event.target.value" />
        </div>
        <div class="vx-col md:w-1/2">
          <vs-select label="Antireflet" autocomplete class="selectExample w-full select-large mt-4" v-model.lazy="nomComAntireflet" @change="setAntirefletPrix(nomComAntireflet)">
            <vs-select-item class="w-full" :key="index" :value="item.libelle" :text="item.libelle" v-for="(item,index) in listeAntireflet" />
          </vs-select>
        </div>
        <div class="vx-col md:w-1/2">
          <vs-input class="w-full mt-4" type="number" label="Prix Antireflet"  name="prixAntireflet" :value="prixAntireflet" @change.native="prixAntireflet=$event.target.value" />
        </div>
        <div class="vx-col md:w-1/2">
          <vs-select label="Autre traitement" autocomplete class="selectExample w-full select-large mt-4" v-model.lazy="nomComAutreTraitement" @change="setAutreTraitementPrix(nomComAutreTraitement)">
            <vs-select-item class="w-full" :key="index" :value="item.libelle" :text="item.libelle" v-for="(item,index) in listeAutreTraitement" />
          </vs-select>
        </div>
        <div class="vx-col md:w-1/2">
          <vs-input class="w-full mt-4" type="number" label="Prix Antre traitement"  name="prixAutreTraitement" :value="prixAutreTraitement" @change.native="prixAutreTraitement=$event.target.value" />
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full ml-auto mt-6">
          <vs-button class="mr-3 mb-2 w-full" @click="generateReportMciCare">Imprimer</vs-button>
        </div>
      </div>
    </vs-popup>
    <div  class="vx-col md:w-3/4">
      <vx-card id="corps-proforma">
        <!-- PDF Generator -->

        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="false"
          :preview-modal="true"
          :paginate-elements-by-height="3508"
          :filename="proforma ? proforma.numProforma : ''"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="90%"

          ref="html2Pdf"
        >
          <section style="padding-left: 45px;padding-top: 10px;" slot="pdf-content">
            <div class="vx-row">
              <div class="vx-col w-full m-h-a4">
                <!-- INVOICE METADATA -->
                <div class="vx-row leading-loose">
                  <div class="vx-col w-1/2 mt-base">
                    <img crossorigin="Anonymous" :src="getModeleEntetePiedPageByOfficine.urlEntete" alt="groupe-logo" height="100px">
                  </div>

                </div>
                <div class="vx-row mt-1">
                  <div class="vx-col w-full">
                    <table class="w-full mb-5 customtable" cellpadding="0" cellspacing="0">
                      <thead>
                        <tr>
                          <th class="text-right" style="border-width: 0px" colspan="12">
                            DATE: {{proforma ? proforma.DateCommd : '' | moment("calendar", "July 10 2011") }}
                          </th>
                        </tr>
                        <tr>
                          <th class="customth" style="border-left-width: 0px;border-right-width: 0px;border-bottom-width: 0px;width:50%;" colspan="6">
                            <!-- PROFORMA N°: {{proforma.numProforma}} <br> -->
                            <barcode :value="proforma ? proforma.numProforma : ''" format="CODE128" displayValue="true" width="2" height="45" >

                            </barcode>
                          </th>
                          <th class="customth" style="border-right-width: 0px;border-bottom-width: 0px;width:50%;" colspan="6">
                            {{client ? client.Civilite : ''}}. {{client ? client.Nom : ''}} {{client ? client.Prenoms : ''}} <br>
                            {{client ? client.Adresse.mobile : ''}} /{{client ? client.Adresse.tel : ''}} <br>
                            {{client ? client.Adresse.email : ''}}
                          </th>
                        </tr>
                        <tr>
                          <th class="" style="border-left-width: 0px;border-right-width: 0px;border-top-width: 0px;width:50%;" colspan="6">

                          </th>
                          <th class="" style="border-right-width: 0px;border-top-width: 0px;width:50%;" colspan="6">

                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <div class="vx-col md:w-2/4">
                    <div class="w-full"></div>
                  </div>
                </div>

                <div class="vx-row">
                  <div class="vx-col w-full">
                    <table class="w-full" cellpadding="0" cellspacing="0">
                      <thead>
                        <tr>
                          <th class="customth" style="border: none"></th>
                          <th class="customth" style="border-width: 0px;">Sph</th>
                          <th class="customth" style="border-width: 0px;">Cyl</th>
                          <th class="customth" style="border-width: 0px;">Axe</th>
                          <th class="customth" style="border-width: 0px;">Add</th>
                          <th class="customth" style="border-width: 0px;">Diam</th>
                        </tr>
                        <tr>
                          <th class="customth" style="border-width: 0px;">OD</th>
                          <th class="customth" style="border-width: 0px;">{{proforma? proforma.SphVD : ''}}</th>
                          <th class="customth" style="border-width: 0px;">{{proforma ? proforma.CylVD : ''}}</th>
                          <th class="customth" style="border-width: 0px;">{{proforma ? proforma.AxeVD : ''}}°</th>
                          <th class="customth" style="border-width: 0px;">{{proforma ? proforma.AddVD : ''}}</th>
                          <th class="customth" style="border-width: 0px;"></th>
                        </tr>
                        <tr>
                          <th class="customth" style="border-width: 0px;">OG</th>
                          <th class="customth" style="border-width: 0px;">{{proforma ? proforma.SphVG : ''}}</th>
                          <th class="customth" style="border-width: 0px;">{{proforma ? proforma.CylVG : ''}}</th>
                          <th class="customth" style="border-width: 0px;">{{proforma ? proforma.AxeVG : ''}}°</th>
                          <th class="customth" style="border-width: 0px;">{{proforma ? proforma.AddVG : ''}}</th>
                          <th class="customth" style="border-width: 0px;"></th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>

                <template v-if="proforma">
                  <template v-if="proforma.libelleMontureClient1 || proforma.RefVerG1 || proforma.RefVerD1 ">
                    <table v-if="proforma.libelleMontureClient1 !='' || proforma.RefVerG1 !='' || proforma.RefVerD1 !=''" class="w-full mt-2" cellpadding="0" cellspacing="0">
                      <thead>
                        <tr>
                          <th class="text-center customth" style="border-bottom-width: 0px; border-right-width: 0px;width:70%" colspan="7">Désignation</th>
                          <th class="text-center customth" style="border-bottom-width: 0px;width:30%" colspan="5">Prix</th>
                        </tr>
                        <tr>
                          <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;width:70%" colspan="7">
                            <strong>MONTURE 1: </strong> {{libelleMonture1 ? libelleMonture1 : null}}
                          </td>
                          <td class="text-right customtd" style="border-bottom-width: 0px;width:30%" colspan="5">{{moneyFormatter((proforma.PrixMont1 ? proforma.PrixMont1 : 0))}}</td>
                        </tr>
                        <tr>
                          <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;border-top-style: dotted;width:70%" colspan="7">
                            Verre OD: {{proforma.RefVerG1}} <br> Verre OG: {{proforma.RefVerD1}}
                          </td>
                          <td class="text-right customtd" style="border-bottom-width: 0px;border-top-style: dotted;width:30%" colspan="5">{{moneyFormatter((totalVerreProposition1 - totalSupProposition1))}}</td>
                        </tr>
                        <tr>
                          <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;border-top-style: dotted;width:70%" colspan="7">
                            <template v-if="proforma.Id_TraitementOD1 || proforma.Id_TraitementOG1">
                              <template v-if="proforma.Id_TraitementOD1 === proforma.Id_TraitementOG1">
                                Traitement ODG: <strong> {{getTraitementById(proforma.Id_TraitementOD1).Libelle_Traitement}}  </strong>
                              </template>
                              <template v-else>
                                Traitement OD: <strong> {{getTraitementById(proforma.Id_TraitementOD1).Libelle_Traitement}}  </strong>
                                <br>
                                Traitement OG: <strong> {{getTraitementById(proforma.Id_TraitementOG1).Libelle_Traitement}}  </strong>
                              </template>
                              <br>
                            </template>
                            <template v-if="proforma.Id_ColorationOD1 || proforma.Id_ColorationOG1">
                              <template v-if="proforma.Id_ColorationOD1 === proforma.Id_ColorationOG1">
                                Coloration ODG: <strong> {{getColorationById(proforma.Id_ColorationOD1).Libelle_Coloration}}  </strong>
                              </template>
                              <template v-else>
                                Coloration OD: <strong> {{getColorationById(proforma.Id_TraitementOD1).Libelle_Coloration}}  </strong>
                                <br>
                                Coloration OG: <strong> {{getColorationById(proforma.Id_TraitementOG1).Libelle_Coloration}}  </strong>
                              </template>
                              <br>
                            </template>
                            <template v-if="proforma.Id_FabricationOD1 || proforma.Id_FabricationOG1">
                              <template v-if="proforma.Id_FabricationOD1 === proforma.Id_FabricationOG1">
                                Supplement ODG: <strong> {{getFabricationById(proforma.Id_FabricationOD1) ? getFabricationById(proforma.Id_FabricationOD1).Libelle_Fabrication : ''}}  </strong>
                              </template>
                              <template v-else>
                                Supplement OD: <strong> {{getFabricationById(proforma.Id_FabricationOD1) ? getFabricationById(proforma.Id_FabricationOD1).Libelle_Fabrication : ''}}  </strong>
                                <br>
                                Supplement OG: <strong> {{getFabricationById(proforma.Id_FabricationOG1) ? getFabricationById(proforma.Id_FabricationOG1).Libelle_Fabrication : ''}}  </strong>
                              </template>
                            </template>
                          </td>
                          <td class="text-right customtd" style="border-bottom-width: 0px;border-top-style: dotted;width:30%" colspan="2">
                            <template v-if="proforma.Id_TraitementOD1 || proforma.Id_TraitementOG1">
                              <template v-if="proforma.Id_TraitementOD1 === proforma.Id_TraitementOG1">
                                <strong> {{moneyFormatter((+getTraitementById(proforma.Id_TraitementOD1).prix_Traitement + +getTraitementById(proforma.Id_TraitementOG1).prix_Traitement))}}  </strong>
                              </template>
                              <template v-else>
                                <strong> {{moneyFormatter(getTraitementById(proforma.Id_TraitementOD1).prix_Traitement)}}  </strong>
                                <br>
                                <strong> {{moneyFormatter(getTraitementById(proforma.Id_TraitementOG1).prix_Traitement)}}  </strong>
                              </template>
                              <br>
                            </template>
                            <template v-if="proforma.Id_ColorationOD1 || proforma.Id_ColorationOG1">
                              <template v-if="proforma.Id_ColorationOD1 === proforma.Id_ColorationOG1">
                                <strong> {{moneyFormatter((+getColorationById(proforma.Id_ColorationOD1).Prix_vente_Coloration + +getColorationById(proforma.Id_ColorationOG1).Prix_vente_Coloration))}}  </strong>
                              </template>
                              <template v-else>
                                <strong> {{moneyFormatter(getColorationById(proforma.Id_ColorationOD1).Prix_vente_Coloration)}}  </strong>
                                <br>
                                <strong> {{moneyFormatter(getColorationById(proforma.Id_ColorationOG1).Prix_vente_Coloration)}}  </strong>
                              </template>
                              <br>
                            </template>
                            <template v-if="proforma.Id_FabricationOD1 || proforma.Id_FabricationOG1">
                              <template v-if="proforma.Id_FabricationOD1 === proforma.Id_FabricationOG1">
                                <strong> {{moneyFormatter((+getFabricationById(proforma.Id_FabricationOD1).Prix_vente_Fabrication + +getFabricationById(proforma.Id_FabricationOG1).Prix_vente_Fabrication))}}  </strong>
                              </template>
                              <template v-else>
                                <strong> {{moneyFormatter(getFabricationById(proforma.Id_FabricationOD1).Prix_vente_Fabrication)}}  </strong>
                                <br>
                                <strong> {{moneyFormatter(getFabricationById(proforma.Id_FabricationOG1).Prix_vente_Fabrication)}}  </strong>
                              </template>
                            </template>
                          </td>
                        </tr>
                        <tr>
                          <td class="customtd" style="border-right-width: 0px;border-left-width: 0px;border-bottom-width: 0px;width:70%" colspan="6">
                            Arrêté la facture proforma de la proposition 1 à la somme de: {{FormatNumberToLetter(totalNet(proforma.PrixMont1, proforma.PrixVerG1 , proforma.PrixVerD1, proforma.RemiseCom))}} FRANCS CFA
                          </td>
                          <td class="customtd" style="border-right-width: 0px;width:15%" colspan="2">
                            Total Brut: <br/>
                            Remise:  <br/>
                            Total Net:
                          </td>
                          <td class="text-right customtd" colspan="2" style="width:15%">
                            {{ moneyFormatter((+(proforma.PrixMont1 ? proforma.PrixMont1 : 0) + +(proforma.PrixVerG1 ? proforma.PrixVerG1 : 0) + +(proforma.PrixVerD1 ? proforma.PrixVerD1 : 0)))}} <br/> {{(proforma.RemiseCom ? proforma.RemiseCom : 0)}} <br/>{{moneyFormatter(totalNet(proforma.PrixMont1, proforma.PrixVerG1 , proforma.PrixVerD1, proforma.RemiseCom))}}
                          </td>
                        </tr>
                      </thead>
                    </table>
                  </template>

                  <template v-if="proforma.libelleMontureClient2 || proforma.RefVerG2 || proforma.RefVerD2 ">
                    <table v-if="proforma.libelleMontureClient2 !='' || proforma.RefVerG2 !='' || proforma.RefVerD2 !=''" class="w-full mt-2" cellpadding="0" cellspacing="0">
                      <thead>
                        <tr>
                          <th class="text-center customth" style="border-bottom-width: 0px; border-right-width: 0px;width:70%" colspan="7">Désignation</th>
                          <th class="text-center customth" style="border-bottom-width: 0px;width:30%" colspan="5">Prix</th>
                        </tr>
                        <tr>
                          <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;width:70%" colspan="7">
                            <strong>MONTURE 2: </strong> {{libelleMonture2  ? libelleMonture2 : null}}
                          </td>
                          <td class="text-right customtd" style="border-bottom-width: 0px;width:30%" colspan="5">{{moneyFormatter((proforma.PrixMont2 ? proforma.PrixMont2 : 0))}}</td>
                        </tr>
                        <tr>
                          <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;border-top-style: dotted;width:70%" colspan="7">
                            Verre OD: {{proforma.RefVerG2}} <br> Verre OG: {{proforma.RefVerD2}}
                          </td>
                          <td class="text-right customtd" style="border-bottom-width: 0px;border-top-style: dotted;width:30%" colspan="5">{{moneyFormatter((totalVerreProposition2 - totalSupProposition2))}}</td>
                        </tr>
                        <tr>
                          <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;border-top-style: dotted;width:70%" colspan="7">
                            <template v-if="proforma.Id_TraitementOD2 || proforma.Id_TraitementOG2">
                              <template v-if="proforma.Id_TraitementOD2 === proforma.Id_TraitementOG2">
                                Traitement ODG: <strong> {{getTraitementById(proforma.Id_TraitementOD2).Libelle_Traitement}}  </strong>
                              </template>
                              <template v-else>
                                Traitement OD: <strong> {{getTraitementById(proforma.Id_TraitementOD2).Libelle_Traitement}}  </strong>
                                <br>
                                Traitement OG: <strong> {{getTraitementById(proforma.Id_TraitementOG2).Libelle_Traitement}}  </strong>
                              </template>
                              <br>
                            </template>
                            <template v-if="proforma.Id_ColorationOD2 || proforma.Id_ColorationOG2">
                              <template v-if="proforma.Id_ColorationOD2 === proforma.Id_ColorationOG2">
                                Coloration ODG: <strong> {{getColorationById(proforma.Id_ColorationOD2).Libelle_Coloration}}  </strong>
                              </template>
                              <template v-else>
                                Coloration OD: <strong> {{getColorationById(proforma.Id_TraitementOD2).Libelle_Coloration}}  </strong>
                                <br>
                                Coloration OG: <strong> {{getColorationById(proforma.Id_TraitementOG2).Libelle_Coloration}}  </strong>
                              </template>
                              <br>
                            </template>
                            <template v-if="proforma.Id_FabricationOD2 || proforma.Id_FabricationOG2">
                              <template v-if="proforma.Id_FabricationOD2 === proforma.Id_FabricationOG2">
                                Supplement ODG: <strong> {{getFabricationById(proforma.Id_FabricationOD2).Libelle_Fabrication}}  </strong>
                              </template>
                              <template v-else>
                                Supplement OD: <strong> {{getFabricationById(proforma.Id_FabricationOD2).Libelle_Fabrication}}  </strong>
                                <br>
                                Supplement OG: <strong> {{getFabricationById(proforma.Id_FabricationOG2).Libelle_Fabrication}}  </strong>
                              </template>
                            </template>
                          </td>
                          <td class="text-right customtd" style="border-bottom-width: 0px;border-top-style: dotted;width:30%" colspan="2">
                            <template v-if="proforma.Id_TraitementOD2 || proforma.Id_TraitementOG2">
                              <template v-if="proforma.Id_TraitementOD2 === proforma.Id_TraitementOG2">
                                <strong> {{moneyFormatter((+getTraitementById(proforma.Id_TraitementOD2).prix_Traitement + +getTraitementById(proforma.Id_TraitementOG2).prix_Traitement))}}  </strong>
                              </template>
                              <template v-else>
                                <strong> {{moneyFormatter(getTraitementById(proforma.Id_TraitementOD2).prix_Traitement)}}  </strong>
                                <br>
                                <strong> {{moneyFormatter(getTraitementById(proforma.Id_TraitementOG2).prix_Traitement)}}  </strong>
                              </template>
                              <br>
                            </template>
                            <template v-if="proforma.Id_ColorationOD2 || proforma.Id_ColorationOG2">
                              <template v-if="proforma.Id_ColorationOD2 === proforma.Id_ColorationOG2">
                                <strong> {{moneyFormatter((+getColorationById(proforma.Id_ColorationOD2).Prix_vente_Coloration + +getColorationById(proforma.Id_ColorationOG2).Prix_vente_Coloration))}}  </strong>
                              </template>
                              <template v-else>
                                <strong> {{moneyFormatter(getColorationById(proforma.Id_ColorationOD2).Prix_vente_Coloration)}}  </strong>
                                <br>
                                <strong> {{moneyFormatter(getColorationById(proforma.Id_ColorationOG2).Prix_vente_Coloration)}}  </strong>
                              </template>
                              <br>
                            </template>
                            <template v-if="proforma.Id_FabricationOD2 || proforma.Id_FabricationOG2">
                              <template v-if="proforma.Id_FabricationOD2 === proforma.Id_FabricationOG2">
                                <strong> {{moneyFormatter((+getFabricationById(proforma.Id_FabricationOD2).Prix_vente_Fabrication + +getFabricationById(proforma.Id_FabricationOG2).Prix_vente_Fabrication))}}  </strong>
                              </template>
                              <template v-else>
                                <strong> {{moneyFormatter(getFabricationById(proforma.Id_FabricationOD2).Prix_vente_Fabrication)}}  </strong>
                                <br>
                                <strong> {{moneyFormatter(getFabricationById(proforma.Id_FabricationOG2).Prix_vente_Fabrication)}}  </strong>
                              </template>
                            </template>
                          </td>
                        </tr>
                        <tr>
                          <td class="customtd" style="border-right-width: 0px;border-left-width: 0px;border-bottom-width: 0px;width:70%" colspan="6">
                            Arrêté la facture proforma de la proposition 2 à la somme de: {{FormatNumberToLetter(totalNet(proforma.PrixMont2, proforma.PrixVerG2 , proforma.PrixVerD2, proforma.RemiseCom))}} FRANCS CFA
                          </td>
                          <td class="customtd" style="border-right-width: 0px;width:15%" colspan="2">
                            Total Brut: <br/>
                            Remise: <br/>
                            Total Net:
                          </td>
                          <td class="text-right customtd" colspan="2" style="width:15%">
                            {{ moneyFormatter((+(proforma.PrixMont2 ? proforma.PrixMont2 : 0) + +(proforma.PrixVerG2 ? proforma.PrixVerG2 : 0) + +(proforma.PrixVerD2 ? proforma.PrixVerD2 : 0)))}} <br/> {{proforma.RemiseCom}} <br/>{{moneyFormatter(totalNet(proforma.PrixMont2, proforma.PrixVerG2 , proforma.PrixVerD2, proforma.RemiseCom))}}
                          </td>
                        </tr>
                      </thead>
                    </table>
                  </template>

                  <template v-if="proforma.libelleMontureClient3 || proforma.RefVerG3 || proforma.RefVerD3 ">
                    <table v-if="proforma.libelleMontureClient3 !='' || proforma.RefVerG3 !='' || proforma.RefVerD3 !=''" class="w-full mt-2" cellpadding="0" cellspacing="0">
                      <thead>
                        <tr>
                          <th class="text-center customth" style="border-bottom-width: 0px; border-right-width: 0px;width:70%" colspan="7">Désignation</th>
                          <th class="text-center customth" style="border-bottom-width: 0px;width:30%" colspan="5">Prix</th>
                        </tr>
                        <tr>
                          <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;width:70%" colspan="7">
                            <strong>MONTURE 3: </strong> {{libelleMonture3 ? libelleMonture3 : null}}
                          </td>
                          <td class="text-right customtd" style="border-bottom-width: 0px;width:30%" colspan="5">{{moneyFormatter((proforma.PrixMont3 ? proforma.PrixMont3 : 0))}}</td>
                        </tr>
                        <tr>
                          <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;border-top-style: dotted;width:70%" colspan="7">
                            Verre OD: {{proforma.RefVerG3}} <br> Verre OG: {{proforma.RefVerD3}}
                          </td>
                          <td class="text-right customtd" style="border-bottom-width: 0px;border-top-style: dotted;width:30%" colspan="5">{{moneyFormatter((totalVerreProposition3 - totalSupProposition3))}}</td>
                        </tr>
                        <tr>
                          <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;border-top-style: dotted;width:70%" colspan="7">
                            <template v-if="proforma.Id_TraitementOD3 || proforma.Id_TraitementOG3">
                              <template v-if="proforma.Id_TraitementOD3 === proforma.Id_TraitementOG3">
                                Traitement ODG: <strong> {{getTraitementById(proforma.Id_TraitementOD3).Libelle_Traitement}}  </strong>
                              </template>
                              <template v-else>
                                Traitement OD: <strong> {{getTraitementById(proforma.Id_TraitementOD3).Libelle_Traitement}}  </strong>
                                <br>
                                Traitement OG: <strong> {{getTraitementById(proforma.Id_TraitementOG3).Libelle_Traitement}}  </strong>
                              </template>
                              <br>
                            </template>
                            <template v-if="proforma.Id_ColorationOD3 || proforma.Id_ColorationOG3">
                              <template v-if="proforma.Id_ColorationOD3 === proforma.Id_ColorationOG3">
                                Coloration ODG: <strong> {{getColorationById(proforma.Id_ColorationOD3).Libelle_Coloration}}  </strong>
                              </template>
                              <template v-else>
                                Coloration OD: <strong> {{getColorationById(proforma.Id_TraitementOD3).Libelle_Coloration}}  </strong>
                                <br>
                                Coloration OG: <strong> {{getColorationById(proforma.Id_TraitementOG3).Libelle_Coloration}}  </strong>
                              </template>
                              <br>
                            </template>
                            <template v-if="proforma.Id_FabricationOD3 || proforma.Id_FabricationOG3">
                              <template v-if="proforma.Id_FabricationOD3 === proforma.Id_FabricationOG3">
                                Supplement ODG: <strong> {{getFabricationById(proforma.Id_FabricationOD3).Libelle_Fabrication}}  </strong>
                              </template>
                              <template v-else>
                                Supplement OD: <strong> {{getFabricationById(proforma.Id_FabricationOD3).Libelle_Fabrication}}  </strong>
                                <br>
                                Supplement OG: <strong> {{getFabricationById(proforma.Id_FabricationOG3).Libelle_Fabrication}}  </strong>
                              </template>
                            </template>
                          </td>
                          <td class="text-right customtd" style="border-bottom-width: 0px;border-top-style: dotted;width:30%" colspan="2">
                            <template v-if="proforma.Id_TraitementOD3 || proforma.Id_TraitementOG3">
                              <template v-if="proforma.Id_TraitementOD3 === proforma.Id_TraitementOG3">
                                <strong> {{moneyFormatter((+getTraitementById(proforma.Id_TraitementOD3).prix_Traitement + +getTraitementById(proforma.Id_TraitementOG3).prix_Traitement))}}  </strong>
                              </template>
                              <template v-else>
                                <strong> {{moneyFormatter(getTraitementById(proforma.Id_TraitementOD3).prix_Traitement)}}  </strong>
                                <br>
                                <strong> {{moneyFormatter(getTraitementById(proforma.Id_TraitementOG3).prix_Traitement)}}  </strong>
                              </template>
                              <br>
                            </template>
                            <template v-if="proforma.Id_ColorationOD3 || proforma.Id_ColorationOG3">
                              <template v-if="proforma.Id_ColorationOD3 === proforma.Id_ColorationOG3">
                                <strong> {{moneyFormatter((+getColorationById(proforma.Id_ColorationOD3).Prix_vente_Coloration + +getColorationById(proforma.Id_ColorationOG3).Prix_vente_Coloration))}}  </strong>
                              </template>
                              <template v-else>
                                <strong> {{moneyFormatter(getColorationById(proforma.Id_ColorationOD3).Prix_vente_Coloration)}}  </strong>
                                <br>
                                <strong> {{moneyFormatter(getColorationById(proforma.Id_ColorationOG3).Prix_vente_Coloration)}}  </strong>
                              </template>
                              <br>
                            </template>
                            <template v-if="proforma.Id_FabricationOD3 || proforma.Id_FabricationOG3">
                              <template v-if="proforma.Id_FabricationOD3 === proforma.Id_FabricationOG3">
                                <strong> {{moneyFormatter((getFabricationById(proforma.Id_FabricationOD3).Prix_vente_Fabrication + +getFabricationById(proforma.Id_FabricationOG3).Prix_vente_Fabrication))}}  </strong>
                              </template>
                              <template v-else>
                                <strong> {{moneyFormatter(getFabricationById(proforma.Id_FabricationOD3).Prix_vente_Fabrication)}}  </strong>
                                <br>
                                <strong> {{moneyFormatter(getFabricationById(proforma.Id_FabricationOG3).Prix_vente_Fabrication)}}  </strong>
                              </template>
                            </template>
                          </td>
                        </tr>
                        <tr>
                          <td class="customtd" style="border-right-width: 0px;border-left-width: 0px;border-bottom-width: 0px;width:70%" colspan="6">
                            Arrêté la facture proforma de la proposition 3 à la somme de: {{FormatNumberToLetter(totalNet(proforma.PrixMont3, proforma.PrixVerG3 , proforma.PrixVerD3, proforma.RemiseCom))}} FRANCS CFA
                          </td>
                          <td class="customtd" style="border-right-width: 0px;width:15%" colspan="2">
                            Total Brut: <br/>
                            Remise: <br/>
                            Total Net:
                          </td>
                          <td class="text-right customtd" colspan="2" style="width:15%">
                            {{ moneyFormatter((+(proforma.PrixMont3 ? proforma.PrixMont3 : 0) + +(proforma.PrixVerG3 ? proforma.PrixVerG3 : 0) + +(proforma.PrixVerD3 ? proforma.PrixVerD3 : 0)))}} <br/> {{(proforma.RemiseCom ? proforma.RemiseCom : 0)}} <br/>{{ moneyFormatter(totalNet(proforma.PrixMont3, proforma.PrixVerG3 , proforma.PrixVerD3, proforma.RemiseCom))}}
                          </td>
                        </tr>
                      </thead>
                    </table>
                  </template>
                  <!-- <vs-divider/> -->

                  <template v-if="(proforma.libelleMontureClient1 || proforma.RefVerG1 || proforma.RefVerD1) && !(proforma.libelleMontureClient2 || proforma.RefVerG2 || proforma.RefVerD2) && !(proforma.libelleMontureClient3 || proforma.RefVerG3 || proforma.RefVerD3)">
                    <br><br><br><br><br><br><br><br><br><br> <br><br>
                    <div class="vx-row mt-5 mb-5">
                      <div class="vx-col w-2/3"></div>
                      <div class="vx-col w-1/3 text-right">
                        <p>Représentant:</p>
                      </div>
                    </div>
                    <br><br><br>
                  </template>

                  <template v-else-if="(proforma.libelleMontureClient1 || proforma.RefVerG1 || proforma.RefVerD1) && (proforma.libelleMontureClient2 || proforma.RefVerG2 || proforma.RefVerD2) && !(proforma.libelleMontureClient3 || proforma.RefVerG3 || proforma.RefVerD3)">
                    <br><br>
                    <div class="vx-row mt-3 mb-5">
                      <div class="vx-col w-2/3"></div>
                      <div class="vx-col w-1/3 text-right">
                        <p>Représentant:</p>
                      </div>
                    </div>
                    <br>
                  </template>

                  <template v-else-if="(proforma.libelleMontureClient1 || proforma.RefVerG1 || proforma.RefVerD1) && (proforma.libelleMontureClient2 || proforma.RefVerG2 || proforma.RefVerD2) && (proforma.libelleMontureClient3 || proforma.RefVerG3 || proforma.RefVerD3)">
                    <div class="vx-row mt-1 mb-5">
                      <div class="vx-col w-2/3"></div>
                      <div class="vx-col w-1/3 text-right">
                        <p>Représentant:</p>
                      </div>
                    </div>
                  </template>

                </template>

                <!-- INVOICE FOOTER -->
                <div class="invoice__footer text-center p-1 mb-auto">
                  <div class="vx-row">
                    <div class="vx-col w-full">
                      <img crossorigin="anonymous" :src="getModeleEntetePiedPageByOfficine.urlPiedPage" alt="groupe-logo" width="100%" height="60px">
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </section>
        </vue-html2pdf>

        <!-- PDF Generator end -->
        <div class="vx-row">
          <div class="vx-col w-full m-h-a4">
            <!-- INVOICE METADATA -->
            <div class="vx-row leading-loose">
              <div class="vx-col w-1/2 mt-base">
                <img crossorigin="anonymous" :src="getModeleEntetePiedPageByOfficine.urlEntete" alt="groupe-logo" height="100px">
              </div>

            </div>
            <vs-divider />
            <div class="vx-row mt-1">
              <div class="vx-col w-full">
                <table class="w-full mb-5 customtable" cellpadding="0" cellspacing="0">
                  <thead>
                    <tr>
                      <th class="text-right" style="border-width: 0px" colspan="12">
                        DATE: {{proforma ? proforma.DateCommd : '' | moment("calendar", "July 10 2011") }}
                      </th>
                    </tr>
                    <tr>
                      <th class="customth" style="border-left-width: 0px;border-right-width: 0px;border-bottom-width: 0px;width:50%;" colspan="6">
                        <!-- PROFORMA N°: {{proforma.numProforma}} <br> -->
                        <barcode :value="(proforma ? proforma.numProforma : null)" format="CODE128" displayValue="true" width="2" height="45" >

                        </barcode>
                      </th>
                      <th class="customth" style="border-right-width: 0px;border-bottom-width: 0px;width:50%;" colspan="6">
                        {{client ? `${client.Civilite}.` : ''}} {{client ? client.Nom : null}} {{client ? client.Prenoms : null}} <br>
                        {{client ? client.Adresse.mobile : ''}} /{{client ? client.Adresse.tel : null}} <br>
                        {{client ? client.Adresse.email : ''}}
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div class="vx-col md:w-2/4">
                <div class="w-full"></div>
              </div>
            </div>
            <template v-if="proforma">
              <div class="vx-row">
                <div class="vx-col w-full">
                  <table class="w-full" cellpadding="0" cellspacing="0">
                    <thead>
                      <tr>
                        <th class="customth" style="border: none"></th>
                        <th class="customth" style="border-width: 0px;">Sph</th>
                        <th class="customth" style="border-width: 0px;">Cyl</th>
                        <th class="customth" style="border-width: 0px;">Axe</th>
                        <th class="customth" style="border-width: 0px;">Add</th>
                        <th class="customth" style="border-width: 0px;">Diam</th>
                      </tr>
                      <tr>
                        <th class="customth" style="border-width: 0px;">OD</th>
                        <th class="customth" style="border-width: 0px;">{{proforma.SphVD}}</th>
                        <th class="customth" style="border-width: 0px;">{{proforma.CylVD}}</th>
                        <th class="customth" style="border-width: 0px;">{{proforma.AxeVD}}°</th>
                        <th class="customth" style="border-width: 0px;">{{proforma.AddVD}}</th>
                        <th class="customth" style="border-width: 0px;"></th>
                      </tr>
                      <tr>
                        <th class="customth" style="border-width: 0px;">OG</th>
                        <th class="customth" style="border-width: 0px;">{{proforma.SphVG}}</th>
                        <th class="customth" style="border-width: 0px;">{{proforma.CylVG}}</th>
                        <th class="customth" style="border-width: 0px;">{{proforma.AxeVG}}°</th>
                        <th class="customth" style="border-width: 0px;">{{proforma.AddVG}}</th>
                        <th class="customth" style="border-width: 0px;"></th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
              <template v-if="proforma.libelleMontureClient1 || proforma.RefVerG1 || proforma.RefVerD1 ">
                <table v-if="proforma.libelleMontureClient1 !='' || proforma.RefVerG1 !='' || proforma.RefVerD1 !=''" class="w-full mt-2" cellpadding="0" cellspacing="0">
                  <thead>
                    <tr>
                      <th class="text-center customth" style="border-bottom-width: 0px; border-right-width: 0px;width:70%" colspan="7">Désignation</th>
                      <th class="text-center customth" style="border-bottom-width: 0px;width:30%" colspan="5">Prix</th>
                    </tr>
                    <tr>
                      <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;width:70%" colspan="7">
                        <strong>MONTURE 1: </strong> {{libelleMonture1 ? libelleMonture1 : null}}
                      </td>
                      <td class="text-right customtd" style="border-bottom-width: 0px;width:30%" colspan="5">{{moneyFormatter((proforma.PrixMont1 ? proforma.PrixMont1 : 0))}}</td>
                    </tr>
                    <tr>
                      <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;border-top-style: dotted;width:70%" colspan="7">
                        Verre OD: {{proforma.RefVerD1}} <br> 
                        Verre OG: {{proforma.RefVerG1}}
                      </td>
                      <td class="text-right customtd" style="border-bottom-width: 0px;border-top-style: dotted;width:30%" colspan="5">
                        {{moneyFormatter((totalVerreProposition1 - totalSupProposition1))}}
                      </td>
                    </tr>
                    <tr>
                      <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;border-top-style: dotted;width:70%" colspan="7">
                        <template v-if="proforma.Id_TraitementOD1 || proforma.Id_TraitementOG1">
                          <template v-if="proforma.Id_TraitementOD1 === proforma.Id_TraitementOG1">
                            Traitement ODG: <strong> {{getTraitementById(proforma.Id_TraitementOD1).Libelle_Traitement}}  </strong>
                          </template>
                          <template v-else>
                            Traitement OD: <strong> {{getTraitementById(proforma.Id_TraitementOD1).Libelle_Traitement}}  </strong>
                            <br>
                            Traitement OG: <strong> {{getTraitementById(proforma.Id_TraitementOG1).Libelle_Traitement}}  </strong>
                          </template>
                          <br>
                        </template>
                        <template v-if="proforma.Id_ColorationOD1 || proforma.Id_ColorationOG1">
                          <template v-if="proforma.Id_ColorationOD1 === proforma.Id_ColorationOG1">
                            Coloration ODG: <strong> {{getColorationById(proforma.Id_ColorationOD1).Libelle_Coloration}}  </strong>
                          </template>
                          <template v-else>
                            Coloration OD: <strong> {{getColorationById(proforma.Id_TraitementOD1).Libelle_Coloration}}  </strong>
                            <br>
                            Coloration OG: <strong> {{getColorationById(proforma.Id_TraitementOG1).Libelle_Coloration}}  </strong>
                          </template>
                          <br>
                        </template>
                        <template v-if="proforma.Id_FabricationOD1 || proforma.Id_FabricationOG1">
                          <template v-if="proforma.Id_FabricationOD1 === proforma.Id_FabricationOG1">
                            Supplement ODG: <strong> {{getFabricationById(proforma.Id_FabricationOD1).Libelle_Fabrication}}  </strong>
                          </template>
                          <template v-else>
                            Supplement OD: <strong> {{getFabricationById(proforma.Id_FabricationOD1).Libelle_Fabrication}}  </strong>
                            <br>
                            Supplement OG: <strong> {{getFabricationById(proforma.Id_FabricationOG1).Libelle_Fabrication}}  </strong>
                          </template>
                        </template>
                      </td>
                      <td class="text-right customtd" style="border-bottom-width: 0px;border-top-style: dotted;width:30%" colspan="2">
                        <template v-if="proforma.Id_TraitementOD1 || proforma.Id_TraitementOG1">
                          <template v-if="proforma.Id_TraitementOD1 === proforma.Id_TraitementOG1">
                            <strong> {{moneyFormatter((+getTraitementById(proforma.Id_TraitementOD1).prix_Traitement + +getTraitementById(proforma.Id_TraitementOG1).prix_Traitement))}}  </strong>
                          </template>
                          <template v-else>
                            <strong> {{moneyFormatter(getTraitementById(proforma.Id_TraitementOD1).prix_Traitement)}}  </strong>
                            <br>
                            <strong> {{moneyFormatter(getTraitementById(proforma.Id_TraitementOG1).prix_Traitement)}}  </strong>
                          </template>
                          <br>
                        </template>
                        <template v-if="proforma.Id_ColorationOD1 || proforma.Id_ColorationOG1">
                          <template v-if="proforma.Id_ColorationOD1 === proforma.Id_ColorationOG1">
                            <strong> {{moneyFormatter((+getColorationById(proforma.Id_ColorationOD1).Prix_vente_Coloration + +getColorationById(proforma.Id_ColorationOG1).Prix_vente_Coloration))}}  </strong>
                          </template>
                          <template v-else>
                            <strong> {{moneyFormatter(getColorationById(proforma.Id_ColorationOD1).Prix_vente_Coloration)}}  </strong>
                            <br>
                            <strong> {{moneyFormatter(getColorationById(proforma.Id_ColorationOG1).Prix_vente_Coloration)}}  </strong>
                          </template>
                          <br>
                        </template>
                        <template v-if="proforma.Id_FabricationOD1 || proforma.Id_FabricationOG1">
                          <template v-if="proforma.Id_FabricationOD1 === proforma.Id_FabricationOG1">
                            <strong> {{moneyFormatter((+getFabricationById(proforma.Id_FabricationOD1).Prix_vente_Fabrication + +getFabricationById(proforma.Id_FabricationOG1).Prix_vente_Fabrication))}}  </strong>
                          </template>
                          <template v-else>
                            <strong> {{moneyFormatter(getFabricationById(proforma.Id_FabricationOD1).Prix_vente_Fabrication)}}  </strong>
                            <br>
                            <strong> {{moneyFormatter(getFabricationById(proforma.Id_FabricationOG1).Prix_vente_Fabrication)}}  </strong>
                          </template>
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <td class="customtd" style="border-right-width: 0px;border-left-width: 0px;border-bottom-width: 0px;width:70%" colspan="6">
                        <strong>Arrêté la facture proforma de la proposition 1 à la somme de: {{FormatNumberToLetter(totalNet(proforma.PrixMont1, proforma.PrixVerG1 , proforma.PrixVerD1, proforma.RemiseCom))}} FRANCS CFA</strong>
                      </td>
                      <td class="customtd" style="border-right-width: 0px;width:15%" colspan="2">
                        <strong>Total Brut: </strong> <br/>
                        <strong>Remise: </strong> <br/>
                        <strong>Total Net: </strong>
                      </td>
                      <td class="text-right customtd" colspan="2" style="width:15%">
                        {{ moneyFormatter((+(proforma.PrixMont1 ? proforma.PrixMont1 : 0) + +(proforma.PrixVerG1 ? proforma.PrixVerG1 : 0) + +(proforma.PrixVerD1 ? proforma.PrixVerD1 : 0)))}} <br/> {{(proforma.RemiseCom ? proforma.RemiseCom : 0)}} <br/>{{moneyFormatter(totalNet(proforma.PrixMont1, proforma.PrixVerG1 , proforma.PrixVerD1, proforma.RemiseCom))}}
                      </td>
                    </tr>
                  </thead>
                </table>
              </template>

              <template v-if="proforma.libelleMontureClient2 || proforma.RefVerG2 || proforma.RefVerD2 ">
                <table v-if="proforma.libelleMontureClient2 !='' || proforma.RefVerG2 !='' || proforma.RefVerD2 !=''" class="w-full mt-2" cellpadding="0" cellspacing="0">
                  <thead>
                    <tr>
                      <th class="text-center customtd" style="border-bottom-width: 0px; border-right-width: 0px;width:70%" colspan="7">Désignation</th>
                      <th class="text-center customtd" style="border-bottom-width: 0px;width:30%" colspan="5">Prix</th>
                    </tr>
                    <tr>
                      <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;width:70%" colspan="7">
                        <strong>MONTURE 2: </strong> {{libelleMonture2  ? libelleMonture2 : null}}
                      </td>
                      <td class="text-right customtd" style="border-bottom-width: 0px;width:30%" colspan="5">{{moneyFormatter((proforma.PrixMont2 ? proforma.PrixMont2 : 0))}}</td>
                    </tr>
                    <tr>
                      <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;border-top-style: dotted;width:70%" colspan="7">
                        Verre OD: {{proforma.RefVerG2}} <br> Verre OG: {{proforma.RefVerD2}}
                      </td>
                      <td class="text-right customtd" style="border-bottom-width: 0px;border-top-style: dotted;width:30%" colspan="5">{{moneyFormatter((totalVerreProposition2 - totalSupProposition2))}}</td>
                    </tr>
                    <tr>
                      <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;border-top-style: dotted;width:70%" colspan="7">
                        <template v-if="proforma.Id_TraitementOD2 || proforma.Id_TraitementOG2">
                          <template v-if="proforma.Id_TraitementOD2 === proforma.Id_TraitementOG2">
                            Traitement ODG: <strong> {{getTraitementById(proforma.Id_TraitementOD2).Libelle_Traitement}}  </strong>
                          </template>
                          <template v-else>
                            Traitement OD: <strong> {{getTraitementById(proforma.Id_TraitementOD2).Libelle_Traitement}}  </strong>
                            <br>
                            Traitement OG: <strong> {{getTraitementById(proforma.Id_TraitementOG2).Libelle_Traitement}}  </strong>
                          </template>
                          <br>
                        </template>
                        <template v-if="proforma.Id_ColorationOD2 || proforma.Id_ColorationOG2">
                          <template v-if="proforma.Id_ColorationOD2 === proforma.Id_ColorationOG2">
                            Coloration ODG: <strong> {{getColorationById(proforma.Id_ColorationOD2).Libelle_Coloration}}  </strong>
                          </template>
                          <template v-else>
                            Coloration OD: <strong> {{getColorationById(proforma.Id_TraitementOD2).Libelle_Coloration}}  </strong>
                            <br>
                            Coloration OG: <strong> {{getColorationById(proforma.Id_TraitementOG2).Libelle_Coloration}}  </strong>
                          </template>
                          <br>
                        </template>
                        <template v-if="proforma.Id_FabricationOD2 || proforma.Id_FabricationOG2">
                          <template v-if="proforma.Id_FabricationOD2 === proforma.Id_FabricationOG2">
                            Supplement ODG: <strong> {{getFabricationById(proforma.Id_FabricationOD2).Libelle_Fabrication}}  </strong>
                          </template>
                          <template v-else>
                            Supplement OD: <strong> {{getFabricationById(proforma.Id_FabricationOD2).Libelle_Fabrication}}  </strong>
                            <br>
                            Supplement OG: <strong> {{getFabricationById(proforma.Id_FabricationOG2).Libelle_Fabrication}}  </strong>
                          </template>
                        </template>
                      </td>
                      <td class="text-right customtd" style="border-bottom-width: 0px;border-top-style: dotted;width:30%" colspan="2">
                        <template v-if="proforma.Id_TraitementOD2 || proforma.Id_TraitementOG2">
                          <template v-if="proforma.Id_TraitementOD2 === proforma.Id_TraitementOG2">
                            <strong> {{moneyFormatter((+getTraitementById(proforma.Id_TraitementOD2).prix_Traitement + +getTraitementById(proforma.Id_TraitementOG2).prix_Traitement))}}  </strong>
                          </template>
                          <template v-else>
                            <strong> {{moneyFormatter(getTraitementById(proforma.Id_TraitementOD2).prix_Traitement)}}  </strong>
                            <br>
                            <strong> {{moneyFormatter(getTraitementById(proforma.Id_TraitementOG2).prix_Traitement)}}  </strong>
                          </template>
                          <br>
                        </template>
                        <template v-if="proforma.Id_ColorationOD2 || proforma.Id_ColorationOG2">
                          <template v-if="proforma.Id_ColorationOD2 === proforma.Id_ColorationOG2">
                            <strong> {{moneyFormatter((+getColorationById(proforma.Id_ColorationOD2).Prix_vente_Coloration + +getColorationById(proforma.Id_ColorationOG2).Prix_vente_Coloration))}}  </strong>
                          </template>
                          <template v-else>
                            <strong> {{moneyFormatter(getColorationById(proforma.Id_ColorationOD2).Prix_vente_Coloration)}}  </strong>
                            <br>
                            <strong> {{moneyFormatter(getColorationById(proforma.Id_ColorationOG2).Prix_vente_Coloration)}}  </strong>
                          </template>
                          <br>
                        </template>
                        <template v-if="proforma.Id_FabricationOD2 || proforma.Id_FabricationOG2">
                          <template v-if="proforma.Id_FabricationOD2 === proforma.Id_FabricationOG2">
                            <strong> {{moneyFormatter((+getFabricationById(proforma.Id_FabricationOD2).Prix_vente_Fabrication + +getFabricationById(proforma.Id_FabricationOG2).Prix_vente_Fabrication))}}  </strong>
                          </template>
                          <template v-else>
                            <strong> {{moneyFormatter(getFabricationById(proforma.Id_FabricationOD2).Prix_vente_Fabrication)}}  </strong>
                            <br>
                            <strong> {{moneyFormatter(getFabricationById(proforma.Id_FabricationOG2).Prix_vente_Fabrication)}}  </strong>
                          </template>
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <td class="customtd" style="border-right-width: 0px;border-left-width: 0px;border-bottom-width: 0px;width:70%" colspan="6">
                        <strong>Arrêté la facture proforma de la proposition 2 à la somme de: {{FormatNumberToLetter(totalNet(proforma.PrixMont2, proforma.PrixVerG2 , proforma.PrixVerD2, proforma.RemiseCom))}} FRANCS CFA</strong>
                      </td>
                      <td class="customtd" style="border-right-width: 0px;width:15%" colspan="2">
                        <strong>Total Brut: </strong> <br/>
                        <strong>Remise: </strong> <br/>
                        <strong>Total Net: </strong>
                      </td>
                      <td class="text-right customtd" colspan="2" style="width:15%">
                        {{ moneyFormatter((+(proforma.PrixMont2 ? proforma.PrixMont2 : 0) + +(proforma.PrixVerG2 ? proforma.PrixVerG2 : 0) + +(proforma.PrixVerD2 ? proforma.PrixVerD2 : 0)))}} <br/> {{proforma.RemiseCom}} <br/>{{moneyFormatter(totalNet(proforma.PrixMont2, proforma.PrixVerG2 , proforma.PrixVerD2, proforma.RemiseCom))}}
                      </td>
                    </tr>
                  </thead>
                </table>
              </template>
              <template v-if="proforma.libelleMontureClient3 || proforma.RefVerG3 || proforma.RefVerD3 ">
                <table v-if="proforma.libelleMontureClient3 !='' || proforma.RefVerG3 !='' || proforma.RefVerD3 !=''" class="w-full mt-2" cellpadding="0" cellspacing="0">
                  <thead>
                    <tr>
                      <th class="text-center customth" style="border-bottom-width: 0px; border-right-width: 0px;width:70%" colspan="7">Désignation</th>
                      <th class="text-center customth" style="border-bottom-width: 0px;width:30%" colspan="5">Prix</th>
                    </tr>
                    <tr>
                      <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;width:70%" colspan="7">
                        <strong>MONTURE 3: </strong> {{libelleMonture3 ? libelleMonture3 : null}}
                      </td>
                      <td class="text-right customtd" style="border-bottom-width: 0px;width:30%" colspan="5">{{moneyFormatter((proforma.PrixMont3 ? proforma.PrixMont3 : 0))}}</td>
                    </tr>
                    <tr>
                      <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;border-top-style: dotted;width:70%" colspan="7">
                        Verre OD: {{proforma.RefVerG3}} <br> Verre OG: {{proforma.RefVerD3}}
                      </td>
                      <td class="text-right customtd" style="border-bottom-width: 0px;border-top-style: dotted;width:30%" colspan="5">{{moneyFormatter((totalVerreProposition3 - totalSupProposition3))}}</td>
                    </tr>
                    <tr>
                      <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;border-top-style: dotted;width:70%" colspan="7">
                        <template v-if="proforma.Id_TraitementOD3 || proforma.Id_TraitementOG3">
                          <template v-if="proforma.Id_TraitementOD3 === proforma.Id_TraitementOG3">
                            Traitement ODG: <strong> {{getTraitementById(proforma.Id_TraitementOD3).Libelle_Traitement}}  </strong>
                          </template>
                          <template v-else>
                            Traitement OD: <strong> {{getTraitementById(proforma.Id_TraitementOD3).Libelle_Traitement}}  </strong>
                            <br>
                            Traitement OG: <strong> {{getTraitementById(proforma.Id_TraitementOG3).Libelle_Traitement}}  </strong>
                          </template>
                          <br>
                        </template>
                        <template v-if="proforma.Id_ColorationOD3 || proforma.Id_ColorationOG3">
                          <template v-if="proforma.Id_ColorationOD3 === proforma.Id_ColorationOG3">
                            Coloration ODG: <strong> {{getColorationById(proforma.Id_ColorationOD3).Libelle_Coloration}}  </strong>
                          </template>
                          <template v-else>
                            Coloration OD: <strong> {{getColorationById(proforma.Id_TraitementOD3).Libelle_Coloration}}  </strong>
                            <br>
                            Coloration OG: <strong> {{getColorationById(proforma.Id_TraitementOG3).Libelle_Coloration}}  </strong>
                          </template>
                          <br>
                        </template>
                        <template v-if="proforma.Id_FabricationOD3 || proforma.Id_FabricationOG3">
                          <template v-if="proforma.Id_FabricationOD3 === proforma.Id_FabricationOG3">
                            Supplement ODG: <strong> {{getFabricationById(proforma.Id_FabricationOD3).Libelle_Fabrication}}  </strong>
                          </template>
                          <template v-else>
                            Supplement OD: <strong> {{getFabricationById(proforma.Id_FabricationOD3).Libelle_Fabrication}}  </strong>
                            <br>
                            Supplement OG: <strong> {{getFabricationById(proforma.Id_FabricationOG3).Libelle_Fabrication}}  </strong>
                          </template>
                        </template>
                      </td>
                      <td class="text-right customtd" style="border-bottom-width: 0px;border-top-style: dotted;width:30%" colspan="2">
                        <template v-if="proforma.Id_TraitementOD3 || proforma.Id_TraitementOG3">
                          <template v-if="proforma.Id_TraitementOD3 === proforma.Id_TraitementOG3">
                            <strong> {{moneyFormatter((+getTraitementById(proforma.Id_TraitementOD3).prix_Traitement + +getTraitementById(proforma.Id_TraitementOG3).prix_Traitement))}}  </strong>
                          </template>
                          <template v-else>
                            <strong> {{moneyFormatter(getTraitementById(proforma.Id_TraitementOD3).prix_Traitement)}}  </strong>
                            <br>
                            <strong> {{moneyFormatter(getTraitementById(proforma.Id_TraitementOG3).prix_Traitement)}}  </strong>
                          </template>
                          <br>
                        </template>
                        <template v-if="proforma.Id_ColorationOD3 || proforma.Id_ColorationOG3">
                          <template v-if="proforma.Id_ColorationOD3 === proforma.Id_ColorationOG3">
                            <strong> {{moneyFormatter((+getColorationById(proforma.Id_ColorationOD3).Prix_vente_Coloration + +getColorationById(proforma.Id_ColorationOG3).Prix_vente_Coloration))}}  </strong>
                          </template>
                          <template v-else>
                            <strong> {{moneyFormatter(getColorationById(proforma.Id_ColorationOD3).Prix_vente_Coloration)}}  </strong>
                            <br>
                            <strong> {{moneyFormatter(getColorationById(proforma.Id_ColorationOG3).Prix_vente_Coloration)}}  </strong>
                          </template>
                          <br>
                        </template>
                        <template v-if="proforma.Id_FabricationOD3 || proforma.Id_FabricationOG3">
                          <template v-if="proforma.Id_FabricationOD3 === proforma.Id_FabricationOG3">
                            <strong> {{moneyFormatter((+getFabricationById(proforma.Id_FabricationOD3).Prix_vente_Fabrication + +getFabricationById(proforma.Id_FabricationOG3).Prix_vente_Fabrication))}}  </strong>
                          </template>
                          <template v-else>
                            <strong> {{moneyFormatter(getFabricationById(proforma.Id_FabricationOD3).Prix_vente_Fabrication)}}  </strong>
                            <br>
                            <strong> {{moneyFormatter(getFabricationById(proforma.Id_FabricationOG3).Prix_vente_Fabrication)}}  </strong>
                          </template>
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <td class="customtd" style="border-right-width: 0px;border-left-width: 0px;border-bottom-width: 0px;width:70%" colspan="6">
                        <strong>Arrêté la facture proforma de la proposition 3 à la somme de: {{FormatNumberToLetter(totalNet(proforma.PrixMont3, proforma.PrixVerG3 , proforma.PrixVerD3, proforma.RemiseCom))}} FRANCS CFA</strong>
                      </td>
                      <td class="customtd" style="border-right-width: 0px;width:15%" colspan="2">
                        <strong>Total Brut: </strong> <br/>
                        <strong>Remise: </strong> <br/>
                        <strong>Total Net: </strong>
                      </td>
                      <td class="text-right customtd" colspan="2" style="width:15%">
                        {{ moneyFormatter((+(proforma.PrixMont3 ? proforma.PrixMont3 : 0) + +(proforma.PrixVerG3 ? proforma.PrixVerG3 : 0) + +(proforma.PrixVerD3 ? proforma.PrixVerD3 : 0)))}} <br/> {{(proforma.RemiseCom ? proforma.RemiseCom : 0)}} <br/>{{ moneyFormatter(totalNet(proforma.PrixMont3, proforma.PrixVerG3 , proforma.PrixVerD3, proforma.RemiseCom))}}
                      </td>
                    </tr>
                  </thead>
                </table>
              </template>

              <!-- <vs-divider/> -->

              <template v-if="(proforma.libelleMontureClient1 || proforma.RefVerG1 || proforma.RefVerD1) && !(proforma.libelleMontureClient2 || proforma.RefVerG2 || proforma.RefVerD2) && !(proforma.libelleMontureClient3 || proforma.RefVerG3 || proforma.RefVerD3)">
                <br><br><br><br><br><br><br><br><br><br> <br>
                <div class="vx-row mb-5">
                  <div class="vx-col w-2/3"></div>
                  <div class="vx-col w-1/3 text-right">
                    <p>Représentant:</p>
                  </div>
                </div>
              </template>

              <template v-else-if="(proforma.libelleMontureClient1 || proforma.RefVerG1 || proforma.RefVerD1) && (proforma.libelleMontureClient2 || proforma.RefVerG2 || proforma.RefVerD2) && !(proforma.libelleMontureClient3 || proforma.RefVerG3 || proforma.RefVerD3)">
                <br><br><br><br><br>
                <div class="vx-row mb-5">
                  <div class="vx-col w-2/3"></div>
                  <div class="vx-col w-1/3 text-right">
                    <p>Représentant:</p>
                  </div>
                </div>
              </template>

            </template>

            <!-- INVOICE FOOTER -->
            <div class="invoice__footer text-center p-1 mt-1">
              <div class="vx-row">
                <div class="vx-col w-full">
                  <img crossorigin="anonymous" :src="getModeleEntetePiedPageByOfficine.urlPiedPage" alt="groupe-logo" width="100%" height="60px">
                </div>
              </div>
            </div>


          </div>
        </div>
        <!-- PDF FORMAT ASSURANCE -->
        <vue-html2pdf 
          :show-layout="false"
          :float-layout="true"
          :enable-download="false"
          :preview-modal="true"
          :paginate-elements-by-height="3508"
          :filename="proforma ? proforma.numProforma : ''"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="90%"

          ref="html2PdfMciCare">
            <section style="padding-left: 93px;padding-top: 8px;" slot="pdf-content">
              <div class="vx-row">
                <div class="vx-col w-full m-h-a4">
                  <div class="vx-row leading-loose">
                    <div class="vx-col w-1/2 mt-base">
                      <img crossorigin="anonymous" :src="getModeleEntetePiedPageByOfficine.urlEntete" alt="groupe-logo" height="100px">
                    </div>

                  </div>
                  <div class="vx-row mt-1">
                    <div class="vx-col w-full">
                      <table class="w-full mb-5 customtable" cellpadding="0" cellspacing="0">
                        <thead>
                          <tr>
                            <th class="customth" style="border-left-width: 0px;border-right-width: 0px;border-bottom-width: 0px;width:50%;" colspan="6">
                              <div>
                                Proforma N°: {{proforma ? proforma.numProforma : '' }}
                              </div>
                              <div>
                                Date d'édition: {{proforma ? proforma.DateCommd : '' | moment("calendar", "July 10 2011") }}
                              </div>
                              <div>
                                Agence: {{officine ? officine.NomOffic : ''}}
                              </div>
                              <div>
                                {{officine ? officine.Adresse.Localite ? officine.Adresse.Localite : '' : '' }}
                              </div>
                            </th>
                            <th class="customth" style="border-right-width: 0px;border-bottom-width: 0px;width:50%;" colspan="6">
                              <div>Client: {{client ? client.Nom : null}} {{client ? client.Prenoms : null}}</div>
                              <div>Contact: {{client ? client.Adresse.mobile : ''}} {{client ? client.Adresse.tel ? `/${client.Adresse.tel}` : '' : null}}</div>
                              <div>Email: {{client ? client.Adresse.email : ''}}</div>
                              <div>Garant: {{ garant }}</div>
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                    <div class="vx-col md:w-2/4">
                      <div class="w-full"></div>
                    </div>
                  </div>
                  <template v-if="proforma">
                    <div class="w-full flex justify-center font-bold">
                      PRESCRIPTION LUNETTE
                    </div>
                    <div class="flex mt-1">
                      <div class="vx-col w-[70%] text-center">
                        <table style="width:70%" class="w-full" cellpadding="0" cellspacing="0">
                          <thead>
                            <tr>
                              <th class="customth" style="border: none"></th>
                              <th class="customth" style="border-bottom-width: 0px; border-right-width: 0px;">Sphère</th>
                              <th class="customth" style="border-bottom-width: 0px; border-right-width: 0px;">Cylindre</th>
                              <th class="customth" style="border-bottom-width: 0px; border-right-width: 0px;">Axe</th>
                              <th class="customth" style="border-bottom-width: 0px;">Addition</th>
                            </tr>
                            <tr>
                              <th class="customth" style="border-bottom-width: 0px;border-right-width: 0px;">Œil Droit</th>
                              <th class="customth" style="border-bottom-width: 0px; border-right-width: 0px;">{{proforma.SphVD}}</th>
                              <th class="customth" style="border-bottom-width: 0px; border-right-width: 0px;">{{proforma.CylVD}}</th>
                              <th class="customth" style="border-bottom-width: 0px; border-right-width: 0px;">{{proforma.AxeVD}}°</th>
                              <th class="customth" style="border-bottom-width: 0px;">{{proforma.AddVD}}</th>
                            </tr>
                            <tr>
                              <th class="customth" style="border-right-width: 0px;">Œil Gauche</th>
                              <th class="customth" style="border-right-width: 0px;">{{proforma.SphVG}}</th>
                              <th class="customth" style="border-right-width: 0px;">{{proforma.CylVG}}</th>
                              <th class="customth" style="border-right-width: 0px;">{{proforma.AxeVG}}°</th>
                              <th class="customth" style="">{{proforma.AddVG}}</th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                    <div class="vx-row mt-5">
                      <div class="vx-col w-full">
                        <div class="w-full flex justify-center font-bold">
                          TARIF MONTURE ET VERRES BLANCS
                        </div>
                        <table class="w-full mt-1" cellpadding="0" cellspacing="0">
                          <thead>
                            <tr>
                              <th class="customth" style="border-bottom-width: 0px; border-right-width: 0px; width:16%" colspan="2">
                                Paramètres
                              </th>
                              <th class="customth" style="border-bottom-width: 0px; border-right-width: 0px; width:45%" colspan="4">
                                Désignation & Référence
                              </th>
                              <th class="customth" style="border-bottom-width: 0px; border-right-width: 0px; width:22%" colspan="3">
                                Montant facturé
                              </th>
                              <th class="customth" style="border-bottom-width: 0px; width:17%" colspan="3">
                                Garantie
                                <!-- Garantie <span class="font-bold">*</span> -->
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px; width:16%" colspan="2" rowspan="2">Monture</td>
                              <td class="customtd text-center" style="border-bottom-width: 0px;width:84%" colspan="10">
                                Fournisseur: {{ propositionPourImpression === '1' ? FourM1 : propositionPourImpression === '2' ? FourM2 : propositionPourImpression === '3' ? FourM3 : '' }}
                              </td>
                            </tr>
                            <tr>
                              <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px; width:45%" colspan="4">
                                <div>
                                  Marque: {{ propositionPourImpression === '1' ? marqueM1 : propositionPourImpression === '2' ? marqueM2 : propositionPourImpression === '3' ? marqueM3 : '' }}
                                </div>
                                <div>
                                  Modèle: {{ propositionPourImpression === '1' ? modeleM1 : propositionPourImpression === '2' ? modeleM2 : propositionPourImpression === '3' ? modeleM3 : '' }}
                                </div>
                                <!-- <div>Taille: {{ TailleM1 }}</div> -->
                                <div>
                                  Etiquette: {{ propositionPourImpression === '1' ? etiquetteM1 : propositionPourImpression === '2' ? etiquetteM2 : propositionPourImpression === '3' ? etiquetteM3 : '' }}
                                </div>
                              </td>
                              <td class="customtd text-right" style="border-bottom-width: 0px; border-right-width: 0px; width:22%" colspan="3">
                                <template v-if="propositionPourImpression === '1'">
                                  {{moneyFormatter((proforma.PrixMont1 ? proforma.PrixMont1 : 0))}}
                                </template>
                                <template v-if="propositionPourImpression === '2'">
                                  {{moneyFormatter((proforma.PrixMont2 ? proforma.PrixMont2 : 0))}}
                                </template>
                                <template v-if="propositionPourImpression === '3'">
                                  {{moneyFormatter((proforma.PrixMont3 ? proforma.PrixMont3 : 0))}}
                                </template>
                              </td>
                              <td class="customtd text-right" style="border-bottom-width: 0px; width:17%" colspan="3">
                                <!-- garentie monture -->
                                <template v-if="propositionPourImpression === '1'">
                                  {{ selectGarantieMonture((proforma.PrixMont1 ? proforma.PrixMont1 : 0)) }}
                                </template>
                                <template v-if="propositionPourImpression === '2'">
                                  {{ selectGarantieMonture((proforma.PrixMont2 ? proforma.PrixMont2 : 0)) }}
                                </template>
                                <template v-if="propositionPourImpression === '3'">
                                  {{ selectGarantieMonture((proforma.PrixMont3 ? proforma.PrixMont3 : 0)) }}
                                </template>
                                <!-- / garentie monture -->
                              </td>
                            </tr>
                            <tr>
                              <td class="customtd" style="border-right-width: 0px; width:16%" colspan="2" rowspan="3">Verres</td>
                              <td class="customtd text-center" style="border-bottom-width: 0px;width:82%" colspan="10">
                                Fournisseur: {{ propositionPourImpression === '1' ? FourV1 : propositionPourImpression === '2' ? FourV2 : propositionPourImpression === '3' ? FourV3 : '' }}
                              </td>
                            </tr>
                            <tr>
                              <td class="customtd" style="border-bottom-width: 0px;border-right-width: 0px; width:45%" colspan="4">
                                <template v-if="propositionPourImpression === '1'">
                                  OD: {{proforma.RefVerD1}}
                                </template>
                                <template v-if="propositionPourImpression === '2'">
                                  OD: {{proforma.RefVerD2}}
                                </template>
                                <template v-if="propositionPourImpression === '3'">
                                  OD: {{proforma.RefVerD3}}
                                </template>
                              </td>
                              <td class="customtd text-right" style="border-bottom-width: 0px; border-right-width: 0px;width:22%" colspan="3">
                                <template v-if="propositionPourImpression === '1'">
                                  {{
                                    moneyFormatter((+(proforma.PrixVerD1 ? proforma.PrixVerD1 : 0) - ((proforma.Prix_TraitementOD1 ? proforma.Prix_TraitementOD1 : 0) + (proforma.Prix_FabricationOD1 ? proforma.Prix_FabricationOD1 : 0) + (proforma.Prix_ColorationOD1 ? proforma.Prix_ColorationOD1 : 0))))
                                  }}
                                </template>
                                <template v-if="propositionPourImpression === '2'">
                                  {{
                                    moneyFormatter((+(proforma.PrixVerD2 ? proforma.PrixVerD2 : 0) - ((proforma.Prix_TraitementOD2 ? proforma.Prix_TraitementOD2 : 0) + (proforma.Prix_FabricationOD2 ? proforma.Prix_FabricationOD2 : 0) + (proforma.Prix_ColorationOD2 ? proforma.Prix_ColorationOD2 : 0))))
                                  }}
                                </template>
                                <template v-if="propositionPourImpression === '3'">
                                  {{
                                    moneyFormatter((+(proforma.PrixVerD3 ? proforma.PrixVerD3 : 0) - ((proforma.Prix_TraitementOD3 ? proforma.Prix_TraitementOD3 : 0) + (proforma.Prix_FabricationOD3 ? proforma.Prix_FabricationOD3 : 0) + (proforma.Prix_ColorationOD3 ? proforma.Prix_ColorationOD3 : 0))))
                                  }}
                                </template>
                              </td>
                              <td class="customtd text-right" style="width:17%" colspan="3" rowspan="3">
                                <!-- garentie -->
                                <template v-if="propositionPourImpression === '1'">
                                  {{ selectGarantieVerre((+(proforma.PrixVerD1 ? proforma.PrixVerD1 : 0) - ((proforma.Prix_TraitementOD1 ? proforma.Prix_TraitementOD1 : 0) + (proforma.Prix_FabricationOD1 ? proforma.Prix_FabricationOD1 : 0) + (proforma.Prix_ColorationOD1 ? proforma.Prix_ColorationOD1 : 0))), (+(proforma.PrixVerG1 ? proforma.PrixVerG1 : 0) - ((proforma.Prix_TraitementOG1 ? proforma.Prix_TraitementOG1 : 0) + (proforma.Prix_FabricationOG1 ? proforma.Prix_FabricationOG1 : 0) + (proforma.Prix_ColorationOG1 ? proforma.Prix_ColorationOG1 : 0)))) }}
                                </template>
                                <template v-if="propositionPourImpression === '2'">
                                  {{ selectGarantieVerre((+(proforma.PrixVerD2 ? proforma.PrixVerD2 : 0) - ((proforma.Prix_TraitementOD2 ? proforma.Prix_TraitementOD2 : 0) + (proforma.Prix_FabricationOD2 ? proforma.Prix_FabricationOD2 : 0) + (proforma.Prix_ColorationOD2 ? proforma.Prix_ColorationOD2 : 0))), (+(proforma.PrixVerG2 ? proforma.PrixVerG2 : 0) - ((proforma.Prix_TraitementOG2 ? proforma.Prix_TraitementOG2 : 0) + (proforma.Prix_FabricationOG2 ? proforma.Prix_FabricationOG2 : 0) + (proforma.Prix_ColorationOG2 ? proforma.Prix_ColorationOG2 : 0)))) }}
                                </template>
                                <template v-if="propositionPourImpression === '3'">
                                  {{ selectGarantieVerre((+(proforma.PrixVerD3 ? proforma.PrixVerD3 : 0) - ((proforma.Prix_TraitementOD3 ? proforma.Prix_TraitementOD3 : 0) + (proforma.Prix_FabricationOD3 ? proforma.Prix_FabricationOD3 : 0) + (proforma.Prix_ColorationOD3 ? proforma.Prix_ColorationOD3 : 0))), (+(proforma.PrixVerG3 ? proforma.PrixVerG3 : 0) - ((proforma.Prix_TraitementOG3 ? proforma.Prix_TraitementOG3 : 0) + (proforma.Prix_FabricationOG3 ? proforma.Prix_FabricationOG3 : 0) + (proforma.Prix_ColorationOG3 ? proforma.Prix_ColorationOG3 : 0)))) }}
                                </template>
                                <!-- / garentie -->
                              </td>
                            </tr>
                            <tr>
                              <td class="customtd" style="border-top-style: dotted; border-right-width: 0px; width:45%" colspan="4">
                                <template v-if="propositionPourImpression === '1'">
                                  OG: {{proforma.RefVerG1}}
                                </template>
                                <template v-if="propositionPourImpression === '2'">
                                  OG: {{proforma.RefVerG2}}
                                </template>
                                <template v-if="propositionPourImpression === '3'">
                                  OG: {{proforma.RefVerG3}}
                                </template>
                              </td>
                              <td class="customtd text-right" style="border-right-width: 0px; border-top-style: dotted; width:22%" colspan="3">
                                <template v-if="propositionPourImpression === '1'">
                                  {{
                                    moneyFormatter((+(proforma.PrixVerG1 ? proforma.PrixVerG1 : 0) - ((proforma.Prix_TraitementOG1 ? proforma.Prix_TraitementOG1 : 0) + (proforma.Prix_FabricationOG1 ? proforma.Prix_FabricationOG1 : 0) + (proforma.Prix_ColorationOG1 ? proforma.Prix_ColorationOG1 : 0))))
                                  }}
                                </template>
                                <template v-if="propositionPourImpression === '2'">
                                  {{
                                    moneyFormatter((+(proforma.PrixVerG2 ? proforma.PrixVerG2 : 0) - ((proforma.Prix_TraitementOG2 ? proforma.Prix_TraitementOG2 : 0) + (proforma.Prix_FabricationOG2 ? proforma.Prix_FabricationOG2 : 0) + (proforma.Prix_ColorationOG2 ? proforma.Prix_ColorationOG2 : 0))))
                                  }}
                                </template>
                                <template v-if="propositionPourImpression === '3'">
                                  {{
                                    moneyFormatter((+(proforma.PrixVerG3 ? proforma.PrixVerG3 : 0) - ((proforma.Prix_TraitementOG3 ? proforma.Prix_TraitementOG3 : 0) + (proforma.Prix_FabricationOG3 ? proforma.Prix_FabricationOG3 : 0) + (proforma.Prix_ColorationOG3 ? proforma.Prix_ColorationOG3 : 0))))
                                  }}
                                </template>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="vx-row mt-5">
                      <div class="vx-col w-full">
                        <div class="w-full flex justify-center font-bold">
                          TRAITEMENTS EN OPTION
                        </div>
                        <table class="w-full mt-3" cellpadding="0" cellspacing="0">
                          <thead>
                            <tr>
                              <th class="customth" style="border: none; width:16%" colspan="2"></th>
                              <th class="customth" style="border-bottom-width: 0px; border-right-width: 0px; width:60%" colspan="7">Nom Commercial Fournisseur</th>
                              <th class="customth" style="border-bottom-width: 0px; width:24%" colspan="3">Montant facturé</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px; width:16%" colspan="2">Photochromique</td>
                              <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px; width:62%" colspan="7">{{ nomComPhotogray }}</td>
                              <td class="customtd text-right" style="border-bottom-width: 0px; width:22%" colspan="3">{{ moneyFormatter(prixPhotogray) }}</td>
                            </tr>
                            <tr>
                              <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px; width:16%" colspan="2">Antireflet</td>
                              <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px; width:62%" colspan="7">{{ nomComAntireflet }}</td>
                              <td class="customtd text-right" style="border-bottom-width: 0px; width:22%" colspan="3">{{ moneyFormatter(prixAntireflet) }}</td>
                            </tr>
                            <tr>
                              <td class="customtd" style="border-right-width: 0px; width:16%" colspan="2">Autres</td>
                              <td class="customtd" style="border-right-width: 0px; width:62%" colspan="7">{{ nomComAutreTraitement }}</td>
                              <td class="customtd text-right" style="width:22%" colspan="3">{{ moneyFormatter(prixAutreTraitement) }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="vx-row mt-5">
                      <div class="vx-col w-full">
                        <table class="w-full" cellpadding="0" cellspacing="0">
                          <thead>
                            <tr>
                              <th class="customth" style="border: none; width:54%" colspan="6"></th>
                              <th class="customth text-right" style="border-bottom-width: 0px; border-right-width: 0px; width:20%" colspan="3">Montant Total</th>
                              <th class="customth text-right" style="border-bottom-width: 0px; width:26%" colspan="3">
                                <template v-if="propositionPourImpression === '1'">
                                  {{ moneyFormatter(montantTotalFactureMci) }}
                                </template>
                                <template v-if="propositionPourImpression === '2'">
                                  {{ moneyFormatter(montantTotal2FactureMci) }}
                                </template>
                                <template v-if="propositionPourImpression === '3'">
                                  {{ moneyFormatter(montantTotal3FactureMci) }}
                                </template>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="customth" style="border: none; width:54%" colspan="6"></td>
                              <td class="customtd text-right" style="border-bottom-width: 0px; border-right-width: 0px; width:20%" colspan="3">Part Assurance</td>
                              <td class="customtd text-right" style="border-bottom-width: 0px; width:26%" colspan="3"></td>
                            </tr>
                            <tr>
                              <td class="customth" style="border: none; width:54%" colspan="6"></td>
                              <td class="customtd text-right" style="border-right-width: 0px; width:20%" colspan="3">Part Assuré(e)</td>
                              <td class="customtd text-right" style="width:26%" colspan="3"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </template>
                  <div class="flex justify-between mt-2">
                    <span>Signature <span class="font-bold">client(e)</span></span>
                    <span>Cachet et signature <span class="font-bold">opticien</span></span>
                  </div>
                  <!-- INVOICE FOOTER -->
                  <div class="invoice__footer text-center p-1 mt-16">
                    <div class="vx-row">
                      <!-- <div class="w-full text-left">
                        <span class="text-sm font-medium">*Voir conditions au verso</span>
                      </div> -->
                      <div class="vx-col w-full">
                        <img crossorigin="anonymous" :src="getModeleEntetePiedPageByOfficine.urlPiedPage" alt="groupe-logo" width="100%" height="60px">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
        </vue-html2pdf>
      </vx-card>
    </div>
    <div class="vx-col md:w-1/4">
      <div class="mb-4">
        <vs-button @click="generateReport()" color="primary" class="w-full text-sm text-left" icon-pack="feather" icon="icon-file" >IMPRIMER</vs-button>
      </div>
      <div class="mb-4">
        <vs-button @click="popupActiveImprime = true" color="primary" class="w-full text-sm text-left" icon-pack="feather" icon="icon-file" >IMPRIMER POUR ASSURANCE</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
const flatPickr = () => import('vue-flatpickr-component')
import 'flatpickr/dist/flatpickr.css'
const VueHtml2pdf = () => import('vue-html2pdf')
import Barcode from 'vue-barcode'
import utils from './../../../conversion-en-lettre/index.js'

export default {
  name: 'Proforma-view',
  components: {
    flatPickr,
    VueHtml2pdf,
    Barcode
  },
  data () {
    return {
      popupActiveImprime: false,
      proforma: null,
      activeUserInfos: JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')) : null,
      propositionPourImpression: '1',

      RefOrd: null,
      libelleMonture1: null,
      libelleMonture2: null,
      libelleMonture3: null,


      client: null,
      IdOfficine: null,
      IdClient: null,


      SphVD: '',
      SphVG: '',
      CylVD: '',
      CylVG: '',
      AxeVD: '',
      AxeVG: '',
      AddVD: '',
      AddVG: '',
      EdVD: '',
      EdVG: '',
      HdVD: '',
      HdVG: '',
      EcartVD1: '',
      EcartVD2: '',
      EcartVG1: '',
      EcartVG2: '',
      HtVD1: '',
      HtVD2: '',
      HtVG1: '',
      HtVG2: '',
      DctrVD1: '',
      DctrVD2: '',
      DctrVG1: '',
      DctrVG2: '',
      DVOVD: '',
      DVOVG: '',
      DLVD: '',
      DLVG: '',

      libelleMontureClient: '',
      OrigineMont1: '',
      PrixMont1: 0,
      RefVerG1: '',
      PrixVerG1: 0,
      RefVerD1: '',
      PrixVerD1: 0,
      Suplement1: '',
      IdMont1: null,
      FourM1: null,
      TailleM1: null,
      FourV1: null,
      etiquetteM1: null,
      marqueM1: null,
      modeleM1: null,
      
      libelleMontureClient2: '',
      OrigineMont2: '',
      PrixMont2: 0,
      RefVerG2: '',
      PrixVerG2: 0,
      RefVerD2: '',
      PrixVerD2: 0,
      Suplement2: '',
      IdMont2: null,
      FourM2: null,
      TailleM2: null,
      FourV2: null,
      etiquetteM2: null,
      marqueM2: null,
      modeleM2: null,

      libelleMontureClient3: '',
      OrigineMont3: '',
      PrixMont3: 0,
      RefVerG3: '',
      PrixVerG3: 0,
      RefVerD3: '',
      PrixVerD3: 0,
      Suplement3: '',
      IdMont3: null,
      FourM3: null,
      TailleM3: null,
      FourV3: null,
      etiquetteM3: null,
      marqueM3: null,
      modeleM3: null,
      
      nomComPhotogray: null,
      prixPhotogray: 0,
      nomComAntireflet: null,
      prixAntireflet: 0,
      garant: null,
      nomComAutreTraitement: null,
      prixAutreTraitement: 0,
      
      Remarque: '',
      TotalFactBrut: 0,
      RemiseCom: 0,
      TotalFactNet: 0,
      PartClient: 0,
      PartAssu: 0,
      PECharge: false,
      CmdeTSalleMontage: false,
      CmdeAuFourVerre: false,
      CmdeMontageFin: false,
      ComdeLivreClient: false,
      TypeMontureOptiq: false,
      propositions: [],
      popupActive: false,
      popoupActivePescripteur: false,
      origineMonture: [
        {
          text: 'Stock',
          value: 'stock'
        },
        {
          text: 'Monture Client',
          value: 'monture_client'
        },
        {
          text: 'Autre',
          value: 'autre'
        }
      ],
      showMontureStockSelect: true,
      showMontureClientInput: false,
      showMontureStockSelectP2: true,
      showMontureClientInputP2: false,
      showCatalogueSelectVerD1: false,
      showCatalogueSelectVerG1: false,
      showCatalogueSelectVerG2: false,
      showCatalogueSelectVerD2: false,
      catalogueVerreG1: '',
      catalogueVerreD1: '',
      prevRoute: null,

      IdCenterHospi:'',
      NomPresc: '',
      PrenomPresc:'',
      Ristourne: false,
      taux:0,
      TelPresc: '',
      Mobile: '',
      FaxPresc: '',
      AdrPresc: '',
      EmailPresc: '',
      RemarqueP:'',

      RaisonSoc: '',
      AdrCli: '',
      MailCli: '',
      TelCli1: '',
      TelCli2: '',
      TelCli3: '',
      MobCli1: '',
      MobCli2: '',
      FaxCli: '',

      montureClearName1: '',
      montureClearName2: '',

      active: false,

      /* PDF Config params */
      showlayout: 'false',
      floatlayout: 'true',
      enabledownload: 'false',
      previewmodal: 'true',
      filename: 'Proforma',
      pdfquality: '2',
      manualpagination: 'false',
      pdfformat: 'a4',
      pdforientation: 'landscape',
      pdfcontentwidth: '100%',

      listePhotochromiques: [
        {
          libelle: 'INFINITY PHOTO',
          prix: 25000
        },
        {
          libelle: '1.56 PHOTO',
          prix: 30000
        },
        {
          libelle: 'PHOTO MAX',
          prix: 60000
        },
        {
          libelle: 'TRANSITION',
          prix: 80000
        },
        {
          libelle: 'CHROMACTIV',
          prix: 50000
        },
        {
          libelle: 'BLUEACTIVE',
          prix: 70000
        },
        {
          libelle: 'XPERIO',
          prix: 50000
        }
      ],
      listeAntireflet: [
        {
          libelle: 'HECO',
          prix: 20000
        },
        {
          libelle: 'CITRINE',
          prix: 30000
        },
        {
          libelle: 'PREMIUM CLEAN',
          prix: 30000
        },
        {
          libelle: 'PREMIUM CLEAN UV',
          prix: 40000
        },
        {
          libelle: 'PREMIUM BLUE BAN',
          prix: 50000
        },
        {
          libelle: 'PREMIUM HD UV',
          prix: 60000
        },
        {
          libelle: 'TOPAZE',
          prix: 30000
        },
        {
          libelle: 'KRISTY',
          prix: 30000
        },
        {
          libelle: 'KARA',
          prix: 40000
        },
        {
          libelle: 'BLEU CUP',
          prix: 50000
        },
        {
          libelle: 'BLEU BLOCK',
          prix: 40000
        },
        {
          libelle: 'BLEU CUT',
          prix: 40000
        },
        {
          libelle: 'OBSYDE',
          prix: 45000
        },
        {
          libelle: 'OBSYDE UV',
          prix: 60000
        },
        {
          libelle: 'SUPRA',
          prix: 40000
        },
        {
          libelle: 'CRIZAL SAPPHIRE',
          prix: 120000
        },
        {
          libelle: 'CRIZAL EASY UV',
          prix: 60000
        },
        {
          libelle: 'CRIZAL ALIZE',
          prix: 80000
        },
        {
          libelle: 'CRIZAL FORTE',
          prix: 100000
        },
        {
          libelle: 'CRIZAL PREVENCIA',
          prix: 120000
        },
        {
          libelle: 'CRIZAL DRIVE',
          prix: 120000
        },
        {
          libelle: 'OPTIFOGUV',
          prix: 120000
        }
      ],
      listeAutreTraitement: [
        {
          libelle: 'PRECAL',
          prix: 20000
        },
        {
          libelle: 'SPHERE ET/OU CYLINDRE SUPERIEUR',
          prix: 70000
        },
        {
          libelle: 'EPAISSEUR SPECIALE',
          prix: 50000
        },
        {
          libelle: 'DIAMETRE REDUIT',
          prix: 50000
        },
        {
          libelle: 'PRISMES',
          prix: 35000
        }
      ],
      fournisseurMontures: [
        {Nom: 'ESSILOR SIVO CI'},
        {Nom: 'OPS'},
        {Nom: 'PORTO ROMANA'},
        {Nom: 'BLUE LIGHT GROUP'},
        {Nom: 'LILIANE TRADING'},
        {Nom: 'LUXOPTIC'}
      ],
      fournisseurVerres: [
        {Nom: 'SIVO'},
        {Nom: 'ESSILOR'},
        {Nom: 'ESSOR'}
      ]
    }
  },
  computed: {
    clientsData () {
      return this.$store.state.client.clients
    },
    totalVerreProposition1 () {
      return ((this.proforma.PrixVerD1 ? +this.proforma.PrixVerD1 : 0) + (this.proforma.PrixVerG1 ? +this.proforma.PrixVerG1 : 0))
    },
    totalSupProposition1 () {
      const traitementOD = this.proforma.Prix_TraitementOD1 ? this.proforma.Prix_TraitementOD1 : 0
      const traitementOG =  this.proforma.Prix_TraitementOG1 ? this.proforma.Prix_TraitementOG1 : 0
      const colorationOD = this.proforma.Prix_ColorationOD1 ? this.proforma.Prix_ColorationOD1 : 0
      const colorationOG = this.proforma.Prix_ColorationOG1 ? this.proforma.Prix_ColorationOG1 : 0
      const fabricationOD = this.proforma.Prix_FabricationOD1 ? this.proforma.Prix_FabricationOD1 : 0
      const fabricationOG = this.proforma.Prix_FabricationOG1 ? this.proforma.Prix_FabricationOG1 : 0

      return (+traitementOD + +traitementOG + +colorationOD + +colorationOG + +fabricationOD + +fabricationOG)
    },
    totalVerreProposition2 () {
      return ((this.proforma.PrixVerG2  ? +this.proforma.PrixVerG2 : 0) + (this.proforma.PrixVerD2 ? +this.proforma.PrixVerD2 : 0))
    },
    totalSupProposition2 () {
      const traitementOD = this.proforma.Prix_TraitementOD2 ? this.proforma.Prix_TraitementOD2 : 0
      const traitementOG =  this.proforma.Prix_TraitementOG2 ? this.proforma.Prix_TraitementOG2 : 0
      const colorationOD = this.proforma.Prix_ColorationOD2 ? this.proforma.Prix_ColorationOD2 : 0
      const colorationOG = this.proforma.Prix_ColorationOG2 ? this.proforma.Prix_ColorationOG2 : 0
      const fabricationOD = this.proforma.Prix_FabricationOD2 ? this.proforma.Prix_FabricationOD2 : 0
      const fabricationOG = this.proforma.Prix_FabricationOG2 ? this.proforma.Prix_FabricationOG2 : 0

      return (+traitementOD + +traitementOG + +colorationOD + +colorationOG + +fabricationOD + +fabricationOG)
    },
    totalVerreProposition3 () {
      return ((this.proforma.PrixVerG3 ? +this.proforma.PrixVerG3 : 0) + (this.proforma.PrixVerD3 ? +this.proforma.PrixVerD3 : 0))
    },
    totalSupProposition3 () {
      const traitementOD = this.proforma.Prix_TraitementOD3 ? this.proforma.Prix_TraitementOD3 : 0
      const traitementOG =  this.proforma.Prix_TraitementOG3 ? this.proforma.Prix_TraitementOG3 : 0
      const colorationOD = this.proforma.Prix_ColorationOD3 ? this.proforma.Prix_ColorationOD3 : 0
      const colorationOG = this.proforma.Prix_ColorationOG3 ? this.proforma.Prix_ColorationOG3 : 0
      const fabricationOD = this.proforma.Prix_FabricationOD3 ? this.proforma.Prix_FabricationOD3 : 0
      const fabricationOG = this.proforma.Prix_FabricationOG3 ? this.proforma.Prix_FabricationOG3 : 0

      return (+traitementOD + +traitementOG + +colorationOD + +colorationOG + +fabricationOD + +fabricationOG)
    },
    getModeleEntetePiedPageByOfficine () {
      return this.$store.getters['entete_piedpage/getModeleEntetePiedPageByOfficine'](this.IdOfficine)
    },
    officines () {
      return this.$store.state.officine.officines
    },
    officine () {
      const id = this.proforma ? this.proforma.IdOfficine : null
      return this.officines.length > 0 ? this.officines.find((o) => o._id === id) : null
    },
    fournisseurs () {
      return this.$store.state.fournisseur.fournisseurs
    },
    fournisseur_verres_data () {
      return this.$store.state.verre.fournisseurs_verres
    },
    totalBruteProposition1 () {
      const Prix_OptionProposition1 = (this.totalVerreProposition1 - this.totalSupProposition1)
      //const Prix_OptionProposition = (this.totalVerreProposition1 - this.totalSupProposition1)
      return Prix_OptionProposition1
    },
    montantTotalFactureMci () {
      const Prix_OptionVerreOD1 = this.proforma ? (+(this.proforma.PrixVerD1 ? this.proforma.PrixVerD1 : 0) - ((this.proforma.Prix_TraitementOD1 ? this.proforma.Prix_TraitementOD1 : 0) + (this.proforma.Prix_FabricationOD1 ? this.proforma.Prix_FabricationOD1 : 0) + (this.proforma.Prix_ColorationOD1 ? this.proforma.Prix_ColorationOD1 : 0))) : 0
      const Prix_OptionVerreOG1 = this.proforma ? (+(this.proforma.PrixVerG1 ? this.proforma.PrixVerG1 : 0) - ((this.proforma.Prix_TraitementOG1 ? this.proforma.Prix_TraitementOG1 : 0) + (this.proforma.Prix_FabricationOG1 ? this.proforma.Prix_FabricationOG1 : 0) + (this.proforma.Prix_ColorationOG1 ? this.proforma.Prix_ColorationOG1 : 0))) : 0
      const PrixMont1 = this.proforma ? this.proforma.PrixMont1 ? this.proforma.PrixMont1 : 0 : 0
      return (+PrixMont1 + +Prix_OptionVerreOD1 + +Prix_OptionVerreOG1 + +this.prixPhotogray + +this.prixAntireflet + +this.prixAutreTraitement)
    },
    montantTotal2FactureMci () {
      const Prix_OptionVerreOD2 = this.proforma ? (+(this.proforma.PrixVerD2 ? this.proforma.PrixVerD2 : 0) - ((this.proforma.Prix_TraitementOD2 ? this.proforma.Prix_TraitementOD2 : 0) + (this.proforma.Prix_FabricationOD2 ? this.proforma.Prix_FabricationOD2 : 0) + (this.proforma.Prix_ColorationOD2 ? this.proforma.Prix_ColorationOD2 : 0))) : 0
      const Prix_OptionVerreOG2 = this.proforma ? (+(this.proforma.PrixVerG2 ? this.proforma.PrixVerG2 : 0) - ((this.proforma.Prix_TraitementOG2 ? this.proforma.Prix_TraitementOG2 : 0) + (this.proforma.Prix_FabricationOG2 ? this.proforma.Prix_FabricationOG2 : 0) + (this.proforma.Prix_ColorationOG2 ? this.proforma.Prix_ColorationOG2 : 0))) : 0
      const PrixMont2 = this.proforma ? this.proforma.PrixMont2 ? this.proforma.PrixMont2 : 0 : 0
      return (+PrixMont2 + +Prix_OptionVerreOD2 + +Prix_OptionVerreOG2 + +this.prixPhotogray + +this.prixAntireflet + +this.prixAutreTraitement)
    },
    montantTotal3FactureMci () {
      const Prix_OptionVerreOD3 = this.proforma ? (+(this.proforma.PrixVerD3 ? this.proforma.PrixVerD3 : 0) - ((this.proforma.Prix_TraitementOD3 ? this.proforma.Prix_TraitementOD3 : 0) + (this.proforma.Prix_FabricationOD3 ? this.proforma.Prix_FabricationOD3 : 0) + (this.proforma.Prix_ColorationOD3 ? this.proforma.Prix_ColorationOD3 : 0))) : 0
      const Prix_OptionVerreOG3 = this.proforma ? (+(this.proforma.PrixVerG3 ? this.proforma.PrixVerG3 : 0) - ((this.proforma.Prix_TraitementOG3 ? this.proforma.Prix_TraitementOG3 : 0) + (this.proforma.Prix_FabricationOG3 ? this.proforma.Prix_FabricationOG3 : 0) + (this.proforma.Prix_ColorationOG3 ? this.proforma.Prix_ColorationOG3 : 0))) : 0
      const PrixMont3 = this.proforma ? this.proforma.PrixMont3 ? this.proforma.PrixMont3 : 0 : 0
      return (+PrixMont3 + +Prix_OptionVerreOD3 + +Prix_OptionVerreOG3 + +this.prixPhotogray + +this.prixAntireflet + +this.prixAutreTraitement)
    }
  },
  methods: {
    selectGarantieMonture (prix) {
      return this.$store.getters['configs/selectGarantieMonture'](prix)
    },
    selectGarantieVerre (montantVerre1, montantVerre2) {
      const totalVerre = +montantVerre1 + +montantVerre2 + +this.prixPhotogray + +this.prixAntireflet + +this.prixAutreTraitement
      return this.$store.getters['configs/selectGarantieVerre'](totalVerre)
    },
    setPhotograyPrix (item) {
      const photo = this.listePhotochromiques.find((p) => p.libelle === item)
      this.prixPhotogray = photo ? photo.prix : 0
    },
    setAntirefletPrix (item) {
      const anti = this.listeAntireflet.find((p) => p.libelle === item)
      this.prixAntireflet = anti ? anti.prix : 0
    },
    setAutreTraitementPrix (item) {
      const autreTraitement = this.listeAutreTraitement.find((p) => p.libelle === item)
      this.prixAutreTraitement = autreTraitement ? autreTraitement.prix : 0
    },
    totalNet (PrixMonture, prixVerG, prixVerD, remise) {
      const montantCommande = (PrixMonture ? +PrixMonture : 0) + +(prixVerG ? +prixVerG : 0) + +(prixVerD ? +prixVerD : 0)
      const total = (montantCommande - ((montantCommande * (remise ? remise : 0)) / 100))
      return  total
    },
    /* conversion nombre en lettre */
    FormatNumberToLetter (nombre) {
      return utils.NumberToLetter(nombre)
    },
    /* fin conversion nombre en lettre */
    generateReport () {

      this.$vs.loading({
        type: 'sound'
      })

      setTimeout(() => {
        this.$vs.loading.close()
      }, 10000)

      this.$refs.html2Pdf.generatePdf()

    },
    generateReportMciCare () {

      this.$vs.loading({
        type: 'sound'
      })

      setTimeout(() => {
        this.$vs.loading.close()
      }, 10000)

      this.$refs.html2PdfMciCare.generatePdf()

    },
    moneyFormatter (montant) {
      /* Formatage du calcul */
      const val = (montant / 1).toFixed(0).replace('.', ',')

      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    getMarqueName (id) {
      const singleMarque = id ? this.$store.getters['marque/getMarqueById'](id) : ''
      return singleMarque ? (singleMarque.Libelle_marq ? singleMarque.Libelle_marq : '') : ''
    },
    getTraitementById (id) {
      return this.$store.getters['verre/getTraitementById'](id)
    },

    getFabricationById (id) {
      return this.$store.getters['verre/getFabricationById'](id)
    },

    getColorationById (id) {
      return this.$store.getters['verre/getColorationById'](id)
    },

    setMontureName1 (id) {
      if (id) {
        this.$store.dispatch('stock/getArticleById', id)
          .then((res) => {
            this.libelleMonture1 =  `${res.data.Etiquette} | ${this.getMarqueName(res.data.IdMarque)} ${res.data.Modele} ${res.data.Couleur}`
            this.FourM1 = this.fournisseurs.length > 0 ? this.fournisseurs.find((f) => f._id === res.data.fourM1) : null
            this.TailleM1 = res.data.Taille ? `${res.data.Taille.Hauteur ? res.data.Taille.Hauteur : '-'}/${res.data.Taille.Nez ? res.data.Taille.Nez : '-'}/${res.data.Taille.Longeur ? res.data.Taille.Longeur : '-'}` : ''
            this.etiquetteM1 = res.data.Etiquette
            this.marqueM1 = this.getMarqueName(res.data.IdMarque)
            this.modeleM1 = res.data.Modele
          })
          .catch(err => {
            console.error(err)
            this.$vs.notify({
              title: 'error',
              text: `${err}`,
              iconPack: 'feather',
              icon: 'icon-error',
              color: 'danger'
            })
          })
      } else {
        return null
      }
    },
    setMontureName2 (id) {
      if (id) {
        this.$store.dispatch('stock/getArticleById', id)
          .then((res) => {
            this.libelleMonture2 =  `${res.data.Etiquette} | ${this.getMarqueName(res.data.IdMarque)} ${res.data.Modele} ${res.data.Couleur}`
            this.FourM2 = this.fournisseurs.length > 0 ? this.fournisseurs.find((f) => f._id === res.data.fourM2) : null
            this.TailleM2 = res.data.Taille ? `${res.data.Taille.Hauteur ? res.data.Taille.Hauteur : '-'}/${res.data.Taille.Nez ? res.data.Taille.Nez : '-'}/${res.data.Taille.Longeur ? res.data.Taille.Longeur : '-'}` : ''
            this.etiquetteM2 = res.data.Etiquette
            this.marqueM2 = this.getMarqueName(res.data.IdMarque)
            this.modeleM2 = res.data.Modele
          })
          .catch(err => {
            console.error(err)
            this.$vs.notify({
              title: 'error',
              text: `${err}`,
              iconPack: 'feather',
              icon: 'icon-error',
              color: 'danger'
            })
          })
      } else {
        return null
      }
    },
    setMontureName3 (id) {
      if (id) {
        this.$store.dispatch('stock/getArticleById', id)
          .then((res) => {
            this.libelleMonture3 =  `${res.data.Etiquette} | ${this.getMarqueName(res.data.IdMarque)} ${res.data.Modele} ${res.data.Couleur}`
            this.FourM3 = this.fournisseurs.length > 0 ? this.fournisseurs.find((f) => f._id === res.data.fourM3) : null
            this.TailleM3 = res.data.Taille ? `${res.data.Taille.Hauteur ? res.data.Taille.Hauteur : '-'}/${res.data.Taille.Nez ? res.data.Taille.Nez : '-'}/${res.data.Taille.Longeur ? res.data.Taille.Longeur : '-'}` : ''
            this.etiquetteM3 = res.data.Etiquette
            this.marqueM3 = this.getMarqueName(res.data.IdMarque)
            this.modeleM3 = res.data.Modele
          })
          .catch(err => {
            console.error(err)
            this.$vs.notify({
              title: 'error',
              text: `${err}`,
              iconPack: 'feather',
              icon: 'icon-error',
              color: 'danger'
            })
          })
      } else {
        return null
      }
    },

    getProformaById () {
      this.$vs.loading({
        type: 'sound'
      })

      this.$store.dispatch('proforma/getProformaById', this.$route.params.proformaId)
        .then((resp) => {

          //console.log(resp.data)
          this.proforma = resp.data

          this.getClientById(this.proforma.IdClient)
          this.setMontureName1(this.proforma.IdMont1)
          this.setMontureName2(this.proforma.IdMont2)
          this.setMontureName3(this.proforma.IdMont3)
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },

    getClientById (id) {
      this.$vs.loading({
        type: 'sound'
      })
      this.$store.dispatch('client/getClientById', id)
        .then((resp) => {
          this.client = resp.data
          this.IdClient = this.client._id
          this.$vs.loading.close()

        })
        .catch(err => {
          console.error(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
          this.$vs.loading.close()
        })
    },
    getMarques () {
      this.$store.dispatch('marque/getMarques')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    activeUserInfo () {
      this.IdOfficine = this.activeUserInfos.IdOfficine
    },
    getAllModelEntetePiedPage () {
      this.$store.dispatch('entete_piedpage/getAllModelEntetePiedPage')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
        })
    },
    getTraitement () {
      this.$store.dispatch('verre/getTraitement')
        .catch((error) => { console.log(error) })
    },
    getFabrications () {
      this.$store.dispatch('verre/getFabrications')
        .then(() => {
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getColorations () {
      this.$store.dispatch('verre/getColorations')
        .then(() => {
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then(() => {
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getFournisseurs () {
      this.$store.dispatch('fournisseur/getFournisseurs')
        .then((resp) => {
          console.log(resp)
        })
        .catch((err) => {
          console.log(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getFournisseursVerres () {
      this.$store.dispatch('verre/getFournisseursVerres')
        .catch((error) => { console.log(error) })
    },
    getGarantie () {
      this.$store.dispatch('configs/getGarantie')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
        })
    }
  },
  mounted () {
    this.getAllModelEntetePiedPage()
    this.activeUserInfo()
    this.getProformaById()
    if (!(this.$store.state.officine.officines.length > 0)) this.getOfficines()
    if (!(this.$store.state.fournisseur.fournisseurs.length > 0)) this.getFournisseurs()
    if (!(this.$store.state.verre.fournisseurs_verres.length > 0)) this.getFournisseursVerres()
    if (!(this.$store.state.marque.marques.length > 0)) this.getMarques()
    if (!(this.$store.state.verre.traitements.length > 0)) this.getTraitement()
    if (!(this.$store.state.verre.fabrications.length > 0)) this.getFabrications()
    if (!(this.$store.state.verre.colorations.length > 0)) this.getColorations()
  },
  created () {
    this.getGarantie()
  }
}
</script>

<style scoped>
.not-data-table{
  display: none;
}

/* th, td {
  border: 1px solid black;
  padding: 4px;
} */

.customtable, .customth, .customtd {
  border: 0.5px solid black;
  border-collapse: collapse;
  /* font-family: 'Times New Roman'; */
  font-size: 13px;
  font-weight: 900;
}

.customtd {
  padding: 5px;
}
</style>
